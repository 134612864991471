import { Link, useParams } from "react-router-dom";

export default function CustomerNavigation() {
  /**
   * 현재 세부 섹션 (기본정보 | 청능 재활 이력 | 말귀통계 | 쿠폰)
   * @property {'info' | 'history' | 'statistics' | 'coupons'} detail - 세부 섹션, 'info', 'history', 'statistics', 'coupons' 값을 가짐
   */
  const { detail, index } = useParams();

  return (
    <>
      <div className="font-bold">
        <p>김동민 고객님 평촌 1센터</p>
      </div>

      <nav className="border-b-2 border-gray-300 border-solid pb-4 px-4">
        <ul className="flex justify-between gap-8 text-center">
          <li className={`flex-1 ${detail === "info" ? "font-bold" : ""}`}>
            <Link
              className="block w-full"
              to={`/main/manager/customer/info/${index}`}
            >
              기본정보
            </Link>
          </li>
          <li className={`flex-1 ${detail === "history" ? "font-bold" : ""}`}>
            <Link
              className="block w-full"
              to={`/main/manager/customer/history/${index}`}
            >
              청능 재활 이력
            </Link>
          </li>
          <li
            className={`flex-1 ${detail === "statistics" ? "font-bold" : ""}`}
          >
            <Link
              className="block w-full"
              to={`/main/manager/customer/statistics/${index}`}
            >
              말귀통계
            </Link>
          </li>
          <li className={`flex-1 ${detail === "coupons" ? "font-bold" : ""}`}>
            <Link
              className="block w-full"
              to={`/main/manager/customer/coupons/${index}`}
            >
              쿠폰
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}
