import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { CustomerItemType } from "@libs/common.types";
import customerData from "@data/customerData.json";
import CustomerNavigation from "./CustomerNavigation";
import CustomerForm from "./CustomerForm";
import useManager from "@hooks/useManager";

// 고객관리 > 기본정보 컴포넌트

export default function CustomerInfo() {
  const { index } = useParams();

  // 커스텀 훅에서 연령(만 나이) 계산 함수 사용
  const { calculateAge } = useManager();

  // 수정 버튼 클릭 여부를 저장하는 상태, true면 고객 정보 수정 컴포넌트 렌더링
  const [isEditing, setIsEditing] = useState(false);
  // 임시 고객 리스트 데이터를 저장하는 상태
  const [userListData, setUserListData] = useState<CustomerItemType[]>();

  // 고객 정보 수정 절차 시작(고객등록 버튼 클릭 시)
  const handleEditing = () => {
    setIsEditing((prev) => !prev);
  };

  // 임시 고객 리스트 데이터 불러오기
  useEffect(() => {
    setUserListData(customerData.data);
  }, []);

  // 고객상세 > 수정 컴포넌트에서 사용할 임시 데이터
  const {
    serialNumber,
    register_date: registrationDate,
    login_account: account,
    name,
    gender,
    birth_date: birthDate,
    phone,
    customer_type: customerType,
    memo
  } = customerData.data[Number(index) - 1];

  return (
    <>
      {!isEditing && userListData ? (
        /** @todo 컴포넌트로 분리 */
        <>
          <CustomerNavigation />

          <div className="flex justify-end items-center pb-8 px-4 border-b-2 border-gray-300 border-solid">
            <button
              className="basis-48 py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl"
              onClick={handleEditing}
            >
              수정
            </button>
          </div>

          <section className="flex flex-col gap-4 items-end">
            <ul className="flex flex-col items-start gap-8 w-full">
              <li className="flex w-full">
                <p className="w-1/4 font-bold">일련번호</p>
                <p>{serialNumber}</p>
              </li>
              <li className="flex w-full">
                <p className="w-1/4 font-bold">이메일</p>
                <p>{account}</p>
              </li>
              <li className="flex w-full">
                <p className="w-1/4 font-bold">등록일</p>
                <p>{registrationDate}</p>
              </li>
              <li className="flex w-full">
                <p className="w-1/4 font-bold">성별</p>
                <p>{gender === "male" ? "남" : "여"}</p>
              </li>
              <li className="flex w-full">
                <p className="w-1/4 font-bold">연령</p>
                <p>{calculateAge(birthDate)}</p>
              </li>
              <li className="flex w-full">
                <p className="w-1/4 font-bold">연락처</p>
                <p>{phone}</p>
              </li>
              <li className="flex w-full">
                <p className="w-1/4 font-bold">고객분류</p>
                <p>{customerType}</p>
              </li>
              <li className="flex w-full">
                <p className="w-1/4 font-bold">메모</p>
                <p>{memo}</p>
              </li>
            </ul>
          </section>
        </>
      ) : (
        <CustomerForm
          mode="edit"
          customerData={{
            serialNumber,
            registrationDate,
            account,
            name,
            gender,
            birthDate,
            phone,
            customerType,
            memo
          }}
          onSubmit={handleEditing}
        />
      )}
    </>
  );
}
