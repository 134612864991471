import { Routes, Route } from "react-router-dom";

import Admin from "@pages/admin/Admin";
import AdminDetail from "@pages/admin/AdminDetail";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route
        path=":section"
        element={<Admin />}
      />
      <Route
        path=":section/:detail/:index"
        element={<AdminDetail />}
      />
    </Routes>
  );
}
