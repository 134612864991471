import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from "react-beautiful-dnd";
import { ReactSVG } from "react-svg";

import { AudioURLType, TrainingItemType } from "@libs/common.types";

import ButtonsMainFilled1 from "@components/common/buttons/ButtonsMainFilled1";
import ButtonsMainOutlined from "@components/common/buttons/ButtonsMainOutlined";
import ButtonsSubFilled from "@components/common/buttons/ButtonsSubFilled";
import ButtonsSubOutlined from "@components/common/buttons/ButtonsSubOutlined";
import { useEffect, useState } from "react";
import LoadingSpinner from "@components/LoadingSpinner";

type Result = {
  isCorrect: boolean;
  correctIndex: number;
};

type SentenceOrderingQuizContentProps = {
  state: { items: any[]; currentIndex: number };
  playingIndex: number | null;
  isStartSolvingQuiz: boolean;
  isSolved: boolean;
  isLoading: boolean;
  trainingItems: TrainingItemType[] | undefined;
  message: string;
  audioUrls: AudioURLType[];
  orderedAudioUrls: AudioURLType[];
  results: Result[];
  onDragEnd: (result: DropResult) => void;
  handlePlayAudio: (index: number) => void;
  handlePlayAudioForItem: (index: number) => void;
  handleSolvingQuiz: () => void;
  handleCheckAnswer: () => void;
  handleReset: () => void;
  navigateToNext: (pathname: string) => void;
};

export default function SentenceOrderingQuizContent({
  state,
  playingIndex,
  isStartSolvingQuiz,
  isSolved,
  isLoading,
  trainingItems,
  message,
  audioUrls,
  orderedAudioUrls,
  results,
  onDragEnd,
  handlePlayAudio,
  handlePlayAudioForItem,
  handleSolvingQuiz,
  handleCheckAnswer,
  handleReset,
  navigateToNext
}: SentenceOrderingQuizContentProps) {
  const [tempItems, setTempItems] = useState(trainingItems); // 드래그 중 실시간 업데이트용

  useEffect(() => {
    // trainingItems가 변경될 때 tempItems를 초기화
    setTempItems(trainingItems);
  }, [trainingItems]);

  return (
    <div className="flex flex-col gap-5 w-full lg:gap-8">
      <p className="font-bold text-lg text-center break-keep lg:text-[32px]">
        {message}
      </p>

      {/* 문장 듣기 UI */}
      {!isStartSolvingQuiz &&
        (!isLoading ? (
          <>
            <div className="grid gap-2 w-full lg:grid-cols-2 lg:gap-4">
              {orderedAudioUrls.map((audio, index) => (
                <div
                  key={index}
                  className="w-full flex justify-center items-center gap-3 py-4 px-3 bg-[#020092] rounded-[20px] text-white lg:py-8 lg:rounded-[32px]"
                >
                  <p className="font-bold text-xl lg:text-2xl">
                    {index + 1}번문장
                  </p>
                  <ButtonsSubOutlined
                    type="button"
                    mode="enable/icon"
                    text=""
                    iconName={`${
                      playingIndex === index ? "ico_pause" : "ico_play"
                    }`}
                    iconStyle="size-full fill-gray70"
                    className="flex justify-center items-center size-[30px] p-1.5"
                    onClick={() => handlePlayAudio(index)}
                  />
                </div>
              ))}
            </div>
            <ButtonsSubFilled
              className="w-fit py-2 px-3 mx-auto lg:text-2xl lg:p-4"
              type="button"
              mode="enable/icon"
              iconName="ico_question_mark"
              iconStyle="size-6 fill-mainColor1"
              text="퀴즈풀기"
              onClick={handleSolvingQuiz}
            />
          </>
        ) : (
          <LoadingSpinner />
        ))}

      {/* 드래그 & 드롭 UI */}
      {isStartSolvingQuiz && !isSolved && (
        <>
          <DragDropContext
            onDragUpdate={(update) => {
              if (!update.destination) return;
              const newItems = Array.from(trainingItems || []);
              const [relocatedItem] = newItems.splice(update.source.index, 1);
              newItems.splice(update.destination.index, 0, relocatedItem);
              setTempItems(newItems); // 드래그 중 상태 업데이트
            }}
            onDragEnd={onDragEnd}
          >
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-col items-center h-full max-w-[600px] mx-auto"
                >
                  {tempItems?.map((item, index) => (
                    <Draggable
                      key={item.index}
                      draggableId={String(item.index)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={`draggable-item ${
                            index === 0 ? "first-item" : ""
                          } ${
                            index === tempItems.length - 1 ? "last-item" : ""
                          } ${snapshot.isDragging ? "dragging" : ""}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="flex flex-col justify-center items-center gap-4 w-14 p-4 bg-mainColor1">
                            <p className="font-bold text-xl text-white">
                              {index + 1}
                            </p>
                            <ButtonsSubOutlined
                              type="button"
                              mode="enable/icon"
                              text=""
                              iconName={`${
                                playingIndex === item.index
                                  ? "ico_pause"
                                  : "ico_play"
                              }`}
                              iconStyle="size-full fill-gray70"
                              className="flex justify-center items-center size-[30px] p-1.5"
                              onClick={() => {
                                handlePlayAudioForItem(item.index);
                              }}
                            />
                          </div>
                          <div className="w-full flex justify-center items-center gap-2 p-4 bg-white break-keep">
                            <p className="w-full text-left leading-6">
                              {item.context}
                            </p>
                            <ReactSVG
                              src={`${process.env.PUBLIC_URL}/images/icons_update/ico_drag.svg`}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <ButtonsSubFilled
            className="w-fit py-2 px-3 mt-3 mx-auto lg:text-2xl lg:p-4"
            type="button"
            mode="enable/icon"
            iconName="ico_check"
            iconStyle="size-6 fill-mainColor1"
            text="정답확인"
            onClick={handleCheckAnswer}
          />
        </>
      )}

      {/* 채점 완료 시 UI */}
      {isSolved && (
        <>
          <div className="flex flex-col items-center h-full max-w-[600px] mx-auto">
            {trainingItems?.map((item, index) => (
              <div
                key={index}
                className="draggable-item w-full"
              >
                <div
                  className={`flex flex-col justify-center items-center gap-4 w-14 p-4 ${
                    results[index].isCorrect ? "bg-green" : "bg-gray60"
                  }`}
                >
                  {results[index].isCorrect ? (
                    <ReactSVG
                      src={`${process.env.PUBLIC_URL}/images/icons_update/ico_check_green.svg`}
                    />
                  ) : (
                    <ReactSVG
                      src={`${process.env.PUBLIC_URL}/images/icons_update/ico_wrong_red.svg`}
                    />
                  )}
                </div>
                <div className="w-full flex justify-center items-center gap-2 p-4 bg-white break-keep">
                  <p className="w-full text-left leading-6">
                    {item.context}{" "}
                    <span>
                      {!results[index].isCorrect &&
                        `(정답: ${results[index].correctIndex}번째)`}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex gap-3 w-full lg:max-w-[600px] mx-auto">
            <ButtonsMainOutlined
              className="p-3 max-sm:text-sm"
              type="button"
              mode="enable/icon"
              text="다시풀기"
              iconName="ico_refresh"
              iconStyle="size-6 fill-mainColor1"
              onClick={handleReset}
            />
            <ButtonsMainFilled1
              className="p-3 max-sm:text-sm"
              type="button"
              mode="enable/icon"
              text={state.items[state.currentIndex + 1] ? "이동하기" : "완료"}
              iconName="ico_play"
              iconStyle="size-6 fill-white"
              onClick={() => navigateToNext(location.pathname)}
            />
          </div>
        </>
      )}
    </div>
  );
}
