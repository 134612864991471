import { useParams } from "react-router-dom";

import AdminManagerLayout from "@components/common/AdminManagerLayout";
import CustomerInfo from "@components/manager/CustomerInfo";
import RehabilitationHistory from "@components/manager/RehabilitationHistory";
import Statistics from "@components/manager/Statistics";
import Coupons from "@components/manager/Coupons";

// 매니저 페이지 > 고객 관리 세부 화면 (기본정보 | 청능 재활 이력 | 말귀통계 | 쿠폰)

export default function ManagerDetail() {
  /**
   * 현재 세부 섹션 (기본정보 | 청능 재활 이력 | 말귀통계 | 쿠폰)
   * @property {'info' | 'history' | 'statistics' | 'coupons'} detail - 세부 섹션, 'info', 'history', 'statistics', 'coupons' 값을 가짐
   */
  const { detail } = useParams();

  return (
    <AdminManagerLayout>
      {detail === "info" && <CustomerInfo />}
      {detail === "history" && <RehabilitationHistory />}
      {detail === "statistics" && <Statistics />}
      {detail === "coupons" && <Coupons />}
    </AdminManagerLayout>
  );
}
