import { Route, Routes } from "react-router-dom";

import LayoutRoutes from "./LayoutRoutes";

import NotFound from "@pages/NotFound";
import ManagerRoutes from "./ManagerRoutes";
import AdminRoutes from "./AdminRoutes";
import AnimatedRoutes from "./AnimatedRoutes";

export default function MainRoutes() {
  return (
    <Routes>
      <Route
        path="/*"
        element={<AnimatedRoutes />}
      />
      <Route
        path="/main/*"
        element={<LayoutRoutes />}
      />
      <Route
        path="*"
        element={<NotFound />}
      />

      {/* 어드민 페이지 라우트 */}
      <Route
        path="/main/admin/*"
        element={<AdminRoutes />}
      />

      {/* 매니저 페이지 라우트 */}
      <Route
        path="/main/manager/*"
        element={<ManagerRoutes />}
      />
    </Routes>
  );
}
