import { ReactSVG } from "react-svg";

export default function Footer() {
  return (
    <footer className="flex justify-center w-screen bg-gray10 mt-[120px] responsive-padding py-14 lg:px-7">
      <div className="flex flex-col gap-6 responsive-max-width">
        <div className="flex justify-between items-center">
          <ReactSVG
            src={`${process.env.PUBLIC_URL}/images/logo/footer_logo.svg`}
          />
          <p className="font-bold">회사소개</p>
        </div>
        <ul className="flex flex-col gap-2 text-xs">
          <li className="flex justify-start items-center gap-2">
            <p className="w-14">대 표</p>
            <p className="text-gray40">&#124;</p>
            <p className="w-full">한지혜</p>
          </li>
          <li className="flex justify-start items-center gap-2">
            <p className="w-14">주 소</p>
            <p className="text-gray40">&#124;</p>
            <p className="w-full">강원도 춘천시 시청길 32 해찬빌딩 3층 302호</p>
          </li>
          <li className="flex justify-start items-center gap-2">
            <p className="w-14 text-center tracking-[0.5px]">고객센터</p>
            <p className="text-gray40">&#124;</p>
            <p className="w-full">
              support@ihab.co.kr &nbsp;&#124;&nbsp; 031-380-3724
            </p>
          </li>
        </ul>
      </div>
    </footer>
  );
}
