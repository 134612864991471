import { useParams } from "react-router-dom";

import AdminManagerLayout from "@components/common/AdminManagerLayout";
import CustomerMain from "@components/manager/CustomerMain";
import SettingsMain from "@components/manager/SettingsMain";

// 매니저 페이지 메인 화면(고객관리 | 훈련세팅)

export default function Manager() {
  /**
   * 현재 접속 섹션 (고객관리 또는 훈련세팅)
   * @property {'customer' | 'settings'} section - 접속 섹션, 'customer' 또는 'settings' 값을 가짐
   */
  const { section } = useParams();

  return (
    <AdminManagerLayout>
      {section === "customer" ? <CustomerMain /> : <SettingsMain />}
    </AdminManagerLayout>
  );
}
