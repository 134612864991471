import { useParams } from "react-router-dom";

import AdminManagerLayout from "@components/common/AdminManagerLayout";
import ManagersMain from "@components/admin/ManagersMain";
import Sales from "@components/admin/Sales";
import Statistics from "@components/admin/Statistics";

export default function Admin() {
  /**
   * 현재 접속 섹션 (매니저 관리 또는 매출/결제 또는 통계)
   * @property {'managers' | 'sales' | 'statistics'} section - 접속 섹션, 'managers' 또는 'sales' 또는 'statistics' 값을 가짐
   */
  const { section } = useParams();

  return (
    <AdminManagerLayout>
      {section === "managers" && <ManagersMain />}
      {section === "sales" && <Sales />}
      {section === "statistics" && <Statistics />}
    </AdminManagerLayout>
  );
}
