import { useState } from "react";

import { ListeningItem, TrainingGroupItemType } from "@libs/common.types";

import useFetchTrainingData from "@hooks/useFetchTrainingData";

import ListeningLinks from "@components/common/TrainingLinkList";
import Pagination from "@components/common/Pagination";

export default function ListeningByTraining() {
  // 페이지네이션 관련 상태
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // 훈련별 듣기 데이터를 가져오고 변환(useFetchTrainingData)
  const { transformedData } = useFetchTrainingData();

  // 페이지에 따라 보여줄 아이템 계산
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = transformedData?.items.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <main className="flex justify-center items-center w-full responsive-padding mt-14 lg:mt-16">
      <div className="flex flex-col justify-center items-center gap-5 responsive-max-width lg:gap-10">
        <div className="flex flex-col justify-center items-center mt-4">
          <p className="text-2xl text-black font-bold mb-1 lg:text-[32px]">
            훈련별 듣기
          </p>
          <p className="text-sm text-gray60 lg:text-xl">
            주제를 선택하여 듣기 연습을 진행하세요
          </p>
        </div>

        {transformedData && (
          <ListeningLinks
            type={transformedData.type}
            items={currentItems as ListeningItem[]}
            categoryData={transformedData.items.map((item) => ({
              p_code: item.category_main_code,
              code: item.category_sub_code
            }))}
            page={currentPage}
          />
        )}

        <Pagination
          transformedData={transformedData as TrainingGroupItemType}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </main>
  );
}
