import { Route, Routes } from "react-router-dom";

import Navigations from "./Navigations";

import Home from "@pages/Home";
import MyPage from "@pages/MyPage";
import Settings from "@pages/Settings";

import LowNoiseListening from "@pages/mobile/LowNoiseListening";
import ShortStoriesListening from "@pages/mobile/ShortStoriesListening";
import LongStoriesListening from "@pages/mobile/LongStoriesListening";
import SentenceOrdering from "@pages/mobile/SentenceOrdering";
import TopicListeningSelect from "@pages/mobile/TopicListeningSelect";
import TopicListeningDetail from "@pages/mobile/TopicListeningDetail";
import CrosswordList from "@pages/mobile/CrosswordList";
import CrosswordItem from "@pages/mobile/CrosswordItem";

import NotFound from "@pages/NotFound";

import { LAYOUT_ROUTES } from "@constants/common.routes";
import DailyTrainingMain from "@pages/mobile/DailyTrainingMain";
import ListeningByTraining from "@pages/mobile/ListeningByTraining";

export default function LayoutRoutes() {
  return (
    <Navigations>
      <Routes>
        <Route
          path="/"
          element={<Home />}
        />
        <Route
          path="/myPage"
          element={<MyPage />}
        />
        <Route
          path="/settings"
          element={<Settings />}
        />
        {/* TODO: 오늘의 듣기 & 어제의 듣기 route 통합 */}
        <Route
          path={`${LAYOUT_ROUTES.DAILY_TRAINING}/:day`}
          element={<DailyTrainingMain />}
        />
        {/* 훈련별 듣기 */}
        <Route
          path={`${LAYOUT_ROUTES.LISTENING_BY_TRAINING}/:training`}
          element={<ListeningByTraining />}
        />
        {/* 소음 하 듣기 */}
        <Route
          path={`${LAYOUT_ROUTES.LISTEN_IN_NOISE}`}
          element={<LowNoiseListening />}
        />
        {/* 짧은 이야기 듣기 */}
        <Route
          path={`${LAYOUT_ROUTES.SHORT_STORY}`}
          element={<ShortStoriesListening />}
        />
        {/* 긴 이야기 듣기 */}
        <Route
          path={`${LAYOUT_ROUTES.LONG_STORY}`}
          element={<LongStoriesListening />}
        />
        {/* 문장 순서화 하기 */}
        <Route
          path={`${LAYOUT_ROUTES.ORDERING_SENTENCE}`}
          element={<SentenceOrdering />}
        />
        {/* 주제별 듣기 */}
        <Route
          path={`${LAYOUT_ROUTES.CATEGORY}/:main`}
          element={<TopicListeningSelect />}
        />
        <Route
          path={`${LAYOUT_ROUTES.CATEGORY}/:main/:sub`}
          element={<TopicListeningDetail />}
        />
        {/* 가로세로 */}
        <Route
          path={`${LAYOUT_ROUTES.CROSSWORD}`}
          element={<CrosswordList />}
        />
        <Route
          path={`${LAYOUT_ROUTES.CROSSWORD}/:quiz`}
          element={<CrosswordItem />}
        />

        {/* 404 페이지 */}
        <Route
          path="/*"
          element={<NotFound />}
        />
      </Routes>
    </Navigations>
  );
}
