import { Link, useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ReactSVG } from "react-svg";

import {
  TaskCompletionState,
  taskCompletionState,
  taskProgressState
} from "@states/progress";
import Utils from "@libs/utils";

import type { TrainingLinkListProps } from "@libs/common.types";

export default function TrainingLinkList({
  type,
  items,
  categoryData,
  page
}: TrainingLinkListProps) {
  const taskCompletion =
    useRecoilValue<TaskCompletionState>(taskCompletionState);
  // const taskProgress = useRecoilValue(taskProgressState); // 진행 상황 상태 가져오기
  const { day } = useParams();
  const location = useLocation();

  const koTitle = Utils.getTrainingTitleNameByKey(type);

  // 경로에 /category/가 포함되어 있는지 확인(주제별 듣기인지)
  const isCategoryPath = location.pathname.includes("/category/");

  const imageMap: { [key: string]: string } = {
    LISTEN_IN_NOISE: "ico_speaker",
    SHORT_STORY: "ico_card",
    LONG_STORY: "ico_board",
    ORDERING_SENTENCE: "ico_sort"
  };
  const imageName = imageMap[type] || "default_image"; // 기본 이미지 설정

  return (
    <section className="flex flex-col justify-start items-center gap-3 w-full p-5 rounded-[20px] shadow-[2px_2px_12px_0px_rgba(0,0,0,0.16)]">
      <div className="flex justify-start items-center gap-4 w-full">
        <ReactSVG
          className="size-10"
          src={`${process.env.PUBLIC_URL}/images/icons_update/${imageName}.svg`}
        />
        <p className="text-xl text-gray80 font-bold lg:text-2xl">{koTitle}</p>
      </div>
      <div
        className={`grid w-full gap-3 lg:grid-cols-2 lg:gap-6 ${
          isCategoryPath ? "grid-cols-2" : ""
        }`}
      >
        {items.length > 0 ? (
          items.map((item, index) => {
            const taskKey = `${type}-${item.name}`; // 주제 이름과 title을 포함한 식별자 설정
            const isCompleted = taskCompletion[taskKey] || false; // taskCompletion 상태에서 해당 key 확인
            const trainingItems = Utils.findTrainingItemsById(type, item.id);
            const isEnabled =
              trainingItems &&
              trainingItems.items &&
              trainingItems.items.length > 0;

            // const progress = isCompleted
            //   ? item.totalQuestions
            //   : taskProgress[taskKey] || 0; // 완료된 경우 전체 문제 개수로 고정

            // URL 경로에 따라 상태 설정 로직을 분기
            const state =
              location.pathname.includes("/today") ||
              location.pathname.includes("/yesterday") ||
              location.pathname.includes("/listening_by_training")
                ? {
                    type: type,
                    title: koTitle,
                    items: items,
                    currentIndex: index,
                    categoryData: {
                      p_code: item.category_main_code,
                      code: item.category_sub_code,
                      page: page
                    }
                  }
                : {
                    type: type,
                    title: koTitle,
                    items: items,
                    currentIndex: index,
                    categoryData: categoryData,
                    p_code: item.category_main_code,
                    code: item.category_sub_code
                  };

            return (
              <Link
                className="flex justify-start items-center gap-3 w-full p-3 bg-gray10 rounded-[100px] lg:rounded-3xl lg:p-8 lg:gap-4"
                to={Utils.generateListeningLinkPath(type, item.name, day)}
                state={state}
                onClick={(e) => {
                  if (!isEnabled) {
                    e.preventDefault();
                    alert("문제 데이터를 준비중입니다.");
                  }
                }}
                key={item.id}
              >
                {!isCategoryPath && (
                  <ReactSVG
                    className="w-3 h-3 flex-shrink-0 lg:w-5 lg:h-5"
                    src={`${
                      process.env.PUBLIC_URL
                    }/images/icons_update/ico_kor_${index + 1}.svg`}
                  />
                )}
                <p
                  className={`w-full text-gray80 font-bold lg:text-2xl ${
                    isCategoryPath ? "text-center" : "text-left"
                  }`}
                >
                  {item.name}
                  {/* 
                    TODO: 문제 상태 관리(taskProgress) 개선
                    {["today", "yesterday"].includes(day as string) &&
                    ["소음하 문장듣기"].includes(title) &&
                    `(${progress}/${item.totalQuestions})`} 
                  */}
                </p>
                {!isCategoryPath && (
                  <ReactSVG
                    className="w-4 h-4 fill-gray50 flex-shrink-0"
                    src={`${process.env.PUBLIC_URL}/images/icons_update/ico_right.svg`}
                  />
                )}
                {/* {isCompleted && (
                  <img
                    className="absolute right-8"
                    src={`${process.env.PUBLIC_URL}/images/icons/icon_task_complete.png`}
                    alt="Completed Icon"
                  />
                )} */}
              </Link>
            );
          })
        ) : (
          <div className="flex justify-start items-center gap-3 w-full py-3 px-5 bg-gray10 rounded-[40px]">
            연습 데이터가 없습니다
          </div>
        )}
      </div>
    </section>
  );
}
