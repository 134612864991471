import React from "react";
import ButtonsMainFilled1 from "./buttons/ButtonsMainFilled1";

type AlertProps = {
  message: string;
  onClose: () => void;
};

export default function Alert({ message, onClose }: AlertProps) {
  const formattedMessage = message.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 text-center w-screen px-5 md:px-5 lg:px-0 break-keep"
      onClick={onClose}
    >
      <div
        className="flex flex-col justify-center items-center gap-4 w-full h-56 bg-white p-6 rounded-lg shadow-lg max-w-[600px]"
        onClick={(e) => e.stopPropagation()}
      >
        <p className="text-[#1a1a1a] leading-6">{formattedMessage}</p>
        <ButtonsMainFilled1
          className="p-5"
          type="button"
          mode="enable"
          text="닫기"
          onClick={onClose}
        />
      </div>
    </div>
  );
}
