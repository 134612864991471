import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Tables } from "@libs/supabase.types";
import Utils from "@libs/utils";
import useAppData from "@hooks/useAppData";

export default function CategoryButton(props: any) {
  const { getCategorySubList } = useAppData();
  const { currentCategoryMain } = props;
  const [categorySubList, setCategorySubList] = useState<
    Tables<"category_sub">[]
  >([]);
  const [bookmarks, setBookmarks] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (currentCategoryMain && currentCategoryMain.code) {
      getCategorySubList(currentCategoryMain.code).then((data) => {
        setCategorySubList(data);
      });
    }
  }, [currentCategoryMain]);

  const handleBookmarkClick = (event: React.MouseEvent, code: string) => {
    event.preventDefault();
    event.stopPropagation();
    setBookmarks((prev) => ({ ...prev, [code]: !prev[code] }));
  };

  if (currentCategoryMain && categorySubList && categorySubList.length > 0) {
    const listElem = categorySubList.map((item: Tables<"category_sub">) => {
      const { addSpacingToWords } = Utils;

      const categoryFolder = item.p_code.toLowerCase();
      const imageFileName = item.code.toLowerCase();
      /** @todo backgroundUrl에 url()을 전달하는 것이 맞는지 검토 */
      const backgroundUrl = `url(${process.env.PUBLIC_URL}/images/backgrounds/${categoryFolder}/${imageFileName}.jpg)`;

      return (
        <Link
          key={item.code}
          to={item.route}
          state={{
            ...item,
            backgroundImage: backgroundUrl
          }}
          className="relative flex justify-between items-center gap-4 size-full lg:gap-5"
          style={
            {
              // backgroundImage: backgroundUrl
            }
          }
        >
          <div
            className="flex justify-center items-center min-w-16 min-h-16 shadow-[0px_20px_20px_0px_rgba(64,160,255,0.10)] rounded-[20px] bg-cover bg-center lg:min-w-[120px] lg:min-h-[120px]"
            style={{
              backgroundImage: backgroundUrl
            }}
          >
            <span className="sr-only">배경이미지</span>
          </div>

          <div className="flex flex-col gap-2 w-full">
            <p className="text-sm text-mainColor1 lg:text-xl">
              {currentCategoryMain.name}
            </p>
            <p className="text-xl text-black font-bold lg:text-[28px]">
              {addSpacingToWords(item.name)}
            </p>
          </div>

          <button
            className="flex justify-end items-center min-w-4"
            onClick={(event) => handleBookmarkClick(event, item.code)}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/icons_update/${
                bookmarks[item.code]
                  ? "ico_bookmark_filled"
                  : "ico_bookmark_outline"
              }.svg`}
              alt="Bookmark Icon"
            />
          </button>
        </Link>
      );
    });
    return <>{listElem}</>;
  } else {
    return <></>;
  }
}
