import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { TrainingCardProps } from "src/types/props.types";

export default function HomeListItem({
  type,
  to,
  completed,
  isInProgress,
  bg,
  items,
  isComplete
}: TrainingCardProps) {
  // `type.key` 값에 따라 이미지 설정
  const imageMap: { [key: string]: string } = {
    LISTEN_IN_NOISE: "ico_speaker",
    SHORT_STORY: "ico_card",
    LONG_STORY: "ico_board",
    ORDERING_SENTENCE: "ico_sort"
  };
  const imageName = imageMap[type.key] || "default_image"; // 기본 이미지 설정

  return (
    <Link
      key={type.key}
      to={to}
      className={`flex justify-between items-center gap-4 w-full h-16 lg:h-[104px] py-3 lg:py-8 px-8 bg-[#F8F8F8] rounded-[100px] lg:rounded-3xl ${
        completed ? "completed" : ""
      } ${isInProgress ? "in-progress" : ""}
      `}
      state={{
        currentIndex: 0,
        items: items,
        type: type.key,
        title: type.name,
        categoryData: {
          p_code: items[0].category_main_code,
          code: items[0].category_sub_code
        }
      }}
      // onClick={() => handleTaskClick(task)}
    >
      <ReactSVG
        className="size-10"
        src={`${process.env.PUBLIC_URL}/images/icons_update/${imageName}.svg`}
      />
      <p className="w-full text-left text-xl lg:text-2xl leading-none font-bold text-gray80 whitespace-nowrap">
        {type.name}
      </p>
      <ReactSVG
        className="w-4 h-4 fill-gray50 flex-shrink-0"
        src={`${process.env.PUBLIC_URL}/images/icons_update/ico_right.svg`}
      />
    </Link>
  );
}
