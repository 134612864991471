import { ReactSVG } from "react-svg";

export default function LoadingSpinner() {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <ReactSVG
        className="animate-spin"
        src={`${process.env.PUBLIC_URL}/images/icons_update/ico_spinner.svg`}
      />
    </div>
  );
}
