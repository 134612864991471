import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { CommonButtonsProps } from "src/types/props.types";

export default function ButtonsSubOutlined({
  mode,
  className,
  type,
  iconName,
  iconStyle,
  text,
  to,
  onClick
}: CommonButtonsProps) {
  const btnStyle = mode.includes("enable")
    ? "border-gray30 text-gray80 bg-white"
    : mode.includes("disable")
    ? "border-gray20 text-gray50 bg-white"
    : "";
  const btnHoverStyle = mode.includes("enable")
    ? "hover:border-gray40 hover:text-gray80 hover:bg-gray10"
    : "cursor-not-allowed";

  return type === "button" ? (
    <button
      className={`buttons-sub-outlined flex gap-1 border border-solid border-gray30 text-gray80 ${btnStyle} ${btnHoverStyle} ${
        className || ""
      }`}
      type="button"
      onClick={onClick}
    >
      {mode.includes("icon") && (
        <ReactSVG
          className={iconStyle}
          src={`${process.env.PUBLIC_URL}/images/icons_update/${iconName}.svg`}
        />
      )}
      {text}
    </button>
  ) : (
    <Link
      to={to as string}
      className={`buttons-sub-outlined flex gap-1 border border-solid border-gray30 text-gray80 ${className} ${btnStyle} ${btnHoverStyle}`}
    >
      {mode.includes("icon") && (
        <ReactSVG
          className={iconStyle}
          src={`${process.env.PUBLIC_URL}/images/icons_update/${iconName}.svg`}
        />
      )}
      {text}
    </Link>
  );
}
