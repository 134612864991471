import { StatisticsTableProps, TableData } from "src/types/props.types";

// categoryName을 한국어로 매핑하기 위한 객체
const categoryNameMap: { [key: string]: string } = {
  LISTEN_IN_NOISE: "소음하 듣기",
  SHORT_STORY: "짧은 이야기",
  LONG_STORY: "긴 이야기",
  ORDERING_SENTENCE: "순서화"
};

// StatisticsTable 컴포넌트
export default function StatisticsTable({ data }: StatisticsTableProps) {
  return (
    <>
      {Object.entries(data).map(([categoryName, tableData]) => (
        <article
          key={categoryName}
          className="flex flex-col gap-4"
        >
          <div className="flex justify-between font-bold">
            <p>{categoryNameMap[categoryName] || categoryName}</p>
            {categoryName === "LISTEN_IN_NOISE" && (
              <p>문장 번호를 클릭하면 내용을 볼 수 있습니다.</p>
            )}
          </div>
          {tableData.map((item, index) => (
            <article
              key={index}
              className="flex"
            >
              <p className="w-1/6">{item.title}</p>
              <table className="w-full text-center border-2 border-gray-300 border-solid">
                <thead>
                  {/** @todo 문제 데이터가 없을 때는 공란으로 표시 */}
                  <tr className="border border-gray-300 border-solid">
                    {Object.keys(item)
                      .filter((key) => key.includes("_result"))
                      .map((key, idx) => (
                        <th
                          key={idx}
                          className="w-1/4 p-2 border-x border-gray-300 border-solid"
                        >
                          문제 {idx + 1}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {Object.keys(item)
                      .filter((key) => key.includes("q"))
                      .map((key, idx) => {
                        const result = item[key as keyof TableData];
                        let resultText = "";
                        let textColorClass = "";

                        if (typeof result === "string") {
                          resultText = result;
                        } else {
                          resultText = result.result || "";
                        }

                        // 오답, 미진행에 따라 스타일 지정
                        if (resultText === "오답") {
                          textColorClass = "text-red-600";
                        } else if (resultText === "미진행") {
                          textColorClass = "text-gray-400";
                        }

                        return (
                          <td
                            key={idx}
                            className={`w-1/4 p-4 border-x border-gray-300 border-solid align-middle ${textColorClass}`}
                          >
                            <span>{resultText}</span>
                            {typeof result !== "string" && result.answer && (
                              <>
                                <br />
                                <span>{result.answer}</span>
                              </>
                            )}
                          </td>
                        );
                      })}
                  </tr>
                </tbody>
              </table>
            </article>
          ))}
        </article>
      ))}
    </>
  );
}
