import Utils from "@libs/utils";
import { ITrainingResponseRecord } from "@libs/common.types";
import ButtonsMainFilled2 from "@components/common/buttons/ButtonsMainFilled2";

type QuestionSelectComponentProps = {
  progress: number;
  resp: ITrainingResponseRecord;
  ansList: string[];
  correctAnswer: string[];
  selAnswer: string[];
  setSelectedAnswer: React.Dispatch<React.SetStateAction<string[]>>;
  isCompleted: boolean;
};

const QuestionsSelectComponent = ({
  progress,
  resp,
  ansList,
  correctAnswer,
  selAnswer,
  setSelectedAnswer,
  isCompleted
}: QuestionSelectComponentProps) => {
  const currentResp = resp[progress];

  const handleMergeAnswer = (answer: string) => {
    setSelectedAnswer([answer]);
  };

  const AnswerStatus = {
    DEFAULT: "enable",
    CORRECT: "correct",
    INCORRECT: "wrong",
    CORRECT_SELECT: "correct/select",
    INCORRECT_SELECT: "wrong/select"
  };

  const getAnswerMode = (
    selectedAnswer: string[],
    correctAns: string[],
    answer: string
  ): string => {
    if (selectedAnswer.length === 0) {
      // 사용자가 아직 응답을 선택하지 않았을 경우
      return AnswerStatus.DEFAULT;
    }

    // 사용자가 응답을 선택한 이후
    if (selectedAnswer.includes(answer)) {
      // 선택된 답변
      if (Utils.arrayHasElem(correctAns, [answer])) {
        return AnswerStatus.CORRECT_SELECT;
      } else {
        return AnswerStatus.INCORRECT_SELECT;
      }
    } else {
      // 선택되지 않은 답변
      if (Utils.arrayHasElem(correctAns, [answer])) {
        return AnswerStatus.CORRECT;
      } else {
        return AnswerStatus.INCORRECT;
      }
    }
  };

  return (
    <div className="flex justify-center items-center flex-wrap gap-3 w-full max-w-[600px]">
      {ansList.map((answer, index) => {
        const id = `answer-${index}`;
        const mode = getAnswerMode(selAnswer, correctAnswer, answer);

        return (
          <ButtonsMainFilled2
            key={id}
            type="button"
            mode={
              mode as
                | "enable"
                | "correct"
                | "wrong"
                | "correct/select"
                | "wrong/select"
            }
            text={answer}
            className="p-3 max-sm:text-sm lg:p-4"
            onClick={() => handleMergeAnswer(answer)}
            disabled={currentResp !== undefined || isCompleted}
          />
        );
      })}
    </div>
  );
};

export default QuestionsSelectComponent;
