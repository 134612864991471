import { ReactSVG } from "react-svg";
import { ButtonsMainFilled2Props } from "src/types/props.types";

export default function ButtonsMainFilled2({
  mode,
  text,
  onClick,
  className,
  disabled
}: ButtonsMainFilled2Props) {
  const getModeClass = (mode: string) => {
    switch (mode) {
      case "correct":
      case "correct/select":
        return "bg-[#04C500] text-white";
      case "wrong":
      case "wrong/select":
        return "bg-gray30 text-gray60";
      default:
        return "bg-gray-200 text-gray-800 hover:bg-gray-300";
    }
  };

  const iconSrc =
    mode === "correct/select"
      ? `${process.env.PUBLIC_URL}/images/icons_update/ico_check_green.svg`
      : `${process.env.PUBLIC_URL}/images/icons_update/ico_wrong_red.svg`;

  return (
    <button
      className={`buttons-main-filled-2 relative ${getModeClass(
        mode
      )} ${className}`}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {mode === "correct/select" || mode === "wrong/select" ? (
        <ReactSVG
          className="absolute -top-2 -right-2"
          src={iconSrc}
        />
      ) : null}
      {text}
    </button>
  );
}
