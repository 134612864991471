import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import useAuth from "@hooks/useAuth";

import { UserAuthState } from "@states/UserAuthState";

import { AdminManagerLayoutProps } from "src/types/props.types";

export default function AdminManagerLayout({
  children
}: AdminManagerLayoutProps) {
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * 현재 접속 섹션 (고객관리 또는 훈련세팅 또는 매니저 관리 또는 매출/결제 또는 통계)
   * @property {'customer' | 'settings' | 'managers' | 'sales' | 'statistics'} section - 접속 섹션, 'customer' 또는 'settings' 또는 'managers' 또는 'sales' 또는 'statistics' 값을 가짐
   */
  const { section } = useParams();

  // 현재 접속 유저 정보
  const { user } = useRecoilValue(UserAuthState);

  const auth = useAuth();

  // 로그아웃 로직
  const logout = async () => {
    const result = await auth.signOut();
    alert("로그아웃 되었습니다.");
    navigate("/");
  };

  // 어드민 페이지 여부 검사
  const isAdminPage = location.pathname.includes("/admin/");

  // 현재 로그인 계정 출력 형식 변환: @ 기호를 기준으로 줄바꿈
  const formattedEmail = user.email.replace("@", "\n@");

  return (
    <div className="flex justify-center items-center w-full font-noto-sans-kr">
      <div className="flex flex-col w-full max-w-[1440px] border-x-2 border-gray-300 border-solid">
        <header className="flex justify-between p-8 border-b-2 border-gray-300 border-solid font-bold text-xl leading-none">
          {/** @todo 로그인한 유저 정보에서 이름 받아오기  */}
          {isAdminPage ? (
            <>
              <p>아이해브 운영 페이지(슈퍼 어드민)</p>
              <p>ihab003 님</p>
            </>
          ) : (
            <>
              <p>말귀 매니저</p>
              <p>(주) 테일러스위프트 보청기 평촌점 님</p>
            </>
          )}
        </header>

        <div className="flex h-[calc(100vh_-_86px)]">
          <nav className="flex flex-col justify-between max-w-48 p-8 border-r-2 border-gray-300 border-solid">
            <div className="flex flex-col gap-8">
              {isAdminPage ? (
                <>
                  <Link
                    className={section === "managers" ? "font-bold" : ""}
                    to="/main/admin/managers"
                  >
                    말귀 매니저 관리
                  </Link>
                  <Link
                    className={section === "sales" ? "font-bold" : ""}
                    to="/main/admin/sales"
                  >
                    매출/결제
                  </Link>
                  <Link
                    className={section === "statistics" ? "font-bold" : ""}
                    to="/main/admin/statistics"
                  >
                    통계
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    className={section === "customer" ? "font-bold" : ""}
                    to="/main/manager/customer"
                  >
                    고객관리
                  </Link>
                  <Link
                    className={section === "settings" ? "font-bold" : ""}
                    to="/main/manager/settings"
                  >
                    훈련세팅
                  </Link>
                </>
              )}
            </div>
            <div className="flex flex-col gap-8">
              {/** @todo 이메일의 길이가 길 때 UX 개선  */}
              <p className="w-full break-words">
                로그인 계정: {formattedEmail}
              </p>
              <button
                className="p-2 bg-gray-300 border-2 border-black border-solid rounded-xl"
                onClick={logout}
              >
                로그아웃
              </button>
            </div>
          </nav>

          <main className="flex flex-col gap-8 w-full p-10">{children}</main>
        </div>
      </div>
    </div>
  );
}
