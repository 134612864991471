import trainingData from "@data/allTrainingData.json";
import { TrainingType, TrainingTypes } from "@constants/trainingType";
import { TrainingDataType } from "./common.types";

const Utils = {
  arrayHasElem<T>(array1: T[], array2: T[]): boolean {
    const arr2 = new Set(array2);

    for (let elem of array1) {
      if (arr2.has(elem)) {
        return true;
      }
    }

    return false;
  },
  arrayMergeAndShuffle<T>(array1: T[], array2: T[]): T[] {
    const combinedArray = [...array1, ...array2];
    for (let i = combinedArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [combinedArray[i], combinedArray[j]] = [
        combinedArray[j],
        combinedArray[i]
      ];
    }
    return combinedArray;
  },
  arraysEqual<T>(array1: T[], array2: T[]): boolean {
    if (array1.length !== array2.length) {
      return false;
    }

    const sortedArray1 = [...array1].sort();
    const sortedArray2 = [...array2].sort();

    for (let i = 0; i < sortedArray1.length; i++) {
      if (sortedArray1[i] !== sortedArray2[i]) {
        return false;
      }
    }

    return true;
  },
  // 문제 데이터 불러오기
  findTrainingItemsById: (
    type: string,
    id: string
  ): TrainingDataType | undefined => {
    if (trainingData.hasOwnProperty(type)) {
      const trainingList = trainingData[
        type as keyof typeof trainingData
      ] as TrainingDataType[];
      const foundItem = trainingList.find((training) => training.id === id);
      if (foundItem) {
        return foundItem;
      }
    }

    return undefined;
  },
  generateAudioBucketPath: (type: string, code?: string): string => {
    const filename = code + ".mp3";
    if (
      type === TrainingType.LISTEN_IN_NOISE ||
      type === TrainingType.ORDERING_SENTENCE
    ) {
      return "LIN_D/" + filename;
    } else if (type === TrainingType.SHORT_STORY) {
      if (code?.includes("A")) return "SHORT_A/" + filename;
      else return "SHORT_S/" + filename;
    } else if (type === TrainingType.LONG_STORY) {
      return "LONG/" + filename;
    } else if (type === TrainingType.CROSSWORD) {
      return "CRWD/" + filename;
    }

    return "";
  },
  generateListeningLinkPath: (
    title: string,
    itemName: string,
    day: string | undefined
  ): string => {
    const basePath = "/main/training";
    const daySegment = day ? `/${day}` : "";
    const pathSegment = title.toLowerCase();

    return `${basePath}${daySegment}/${pathSegment}/${encodeURIComponent(
      itemName
    )}`;
  },
  getTrainingTitleNameByKey: (key: string) => {
    const name = TrainingTypes.find((item) => item.key === key)?.name;
    return name ? name : "";
  },
  getTrainingTitleColorStr: (title: string) => {
    let color;
    switch (title) {
      case TrainingType.LISTEN_IN_NOISE:
        color = "blue";
        break;
      case TrainingType.SHORT_STORY:
        color = "magenta";
        break;
      case TrainingType.LONG_STORY:
        color = "purple";
        break;
      default:
        color = "mint";
        break;
    }
    return color;
  },
  validateDate: (date: string | undefined) => {
    if (!date) {
      return false;
    }
    const dateReg = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateReg.test(date)) {
      return false;
    }
    const dateArr = date.split("-");
    const year = Number(dateArr[0]);
    const month = Number(dateArr[1]);
    const day = Number(dateArr[2]);
    if (month < 1 || month > 12) {
      return false;
    }
    if (day < 1 || day > 31) {
      return false;
    }
    if (
      (month === 4 || month === 6 || month === 9 || month === 11) &&
      day === 31
    ) {
      return false;
    }
    if (month === 2) {
      const isLeapYear =
        (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      if (day > 29 || (day === 29 && !isLeapYear)) {
        return false;
      }
    }
    return true;
  },
  changeDate: (value: string) => {
    let DataFormat: any;
    let RegDateFmt: any;

    const RegNotNum = /[^0-9]/g;
    const onlyNum = value.replace(RegNotNum, "");

    if (onlyNum.length <= 6) {
      DataFormat = "$1-$2";
      RegDateFmt = /([0-9]{4})([0-9]+)/;
    } else if (onlyNum.length <= 8) {
      DataFormat = "$1-$2-$3";
      RegDateFmt = /([0-9]{4})([0-9]{2})([0-9]+)/;
    }

    const newDate = onlyNum.replace(RegDateFmt, DataFormat);

    return newDate;
  },
  changePhone: (value: string) => {
    let DataFormat: any;
    let RegPhoneFmt: any;

    const RegNotNum = /[^0-9]/g;
    const onlyNum = value.replace(RegNotNum, "");

    if (onlyNum.length <= 3) {
      DataFormat = "$1";
      RegPhoneFmt = /([0-9]{1,3})/;
    } else if (onlyNum.length <= 7) {
      DataFormat = "$1-$2";
      RegPhoneFmt = /([0-9]{3})([0-9]+)/;
    } else if (onlyNum.length <= 11) {
      DataFormat = "$1-$2-$3";
      RegPhoneFmt = /([0-9]{3})([0-9]{3,4})([0-9]{1,4})/;
    } else {
      DataFormat = "$1-$2-$3";
      RegPhoneFmt = /([0-9]{3})([0-9]{4})([0-9]{4})/;
    }

    const newPhone = onlyNum.replace(RegPhoneFmt, DataFormat);

    return newPhone;
  },
  /** 공백 추가 함수 */
  addSpacingToWords: (text: string) => {
    // 띄어쓰기를 적용할 규칙을 배열로 정의합니다.
    // 각 규칙은 정규 표현식 패턴과 해당 패턴에 대한 대체 문자열을 포함합니다.
    const rules = [
      // '의', '와', '별' 뒤에 한글이 오면 공백을 추가합니다.
      { pattern: /(의|와|별)(?=[가-힣])/g, replacement: "$1 " },
      // '과' 뒤에 공백을 추가합니다.
      // 그러나 '과' 바로 뒤에 '일'이나 '학'으로 시작하는 어떤 한글 단어가 오면 공백을 추가하지 않습니다 ('과일', '과학' 등을 유지).
      { pattern: /과(?![가-힣]*일|학)(?=[가-힣])/g, replacement: "과 " },
      // '한' 뒤에 공백을 추가합니다.
      // 그러나 '한' 바로 뒤에 '국'으로 시작하는 어떤 한글 단어가 오면 공백을 추가하지 않습니다 ('한국' 등을 유지).
      { pattern: /한(?![가-힣]*국)(?=[가-힣])/g, replacement: "한 " },
      // '한국' 다음에 '의'가 오면 '한국'을 그대로 유지합니다 (공백 없이).
      { pattern: /한국(?=의)/g, replacement: "한국" },
      // '한국' 뒤에 '의'를 제외한 한글이 오면 공백을 추가합니다.
      { pattern: /한국(?![가-힣]*의)(?=[가-힣])/g, replacement: "한국 " },
      // '현대' 뒤에 한글이 오면 공백을 추가합니다.
      { pattern: /현대(?=[가-힣])/g, replacement: "현대 " },
      // 단어 끝에 '상식'이 오면 앞에 공백을 추가합니다.
      { pattern: /(?<=\S)상식/g, replacement: " 상식" },
      // '시대' 뒤에 한글이 오면 공백을 추가합니다.
      { pattern: /시대(?=[가-힣])/g, replacement: "시대 " },
      // '속'의 앞뒤로 공백을 추가합니다.
      { pattern: /속/g, replacement: " 속 " },
      // '길' 뒤에 한글이 오면 공백을 추가합니다.
      { pattern: /길(?=[가-힣])/g, replacement: "길 " }
    ];

    // 정의된 규칙을 순회하면서 각 패턴에 대해 텍스트를 수정합니다.
    rules.forEach((rule) => {
      text = text.replace(rule.pattern, rule.replacement);
    });

    // 모든 규칙 적용 후, 연속된 공백을 하나의 공백으로 줄이고, 문장의 시작과 끝의 공백을 제거합니다.
    text = text.replace(/\s+/g, " ").trim();

    return text;
  },
  // 문장 순서화 보기 셔플 함수
  shuffleArray: (array: any[]) => {
    let shuffledArray;
    do {
      shuffledArray = [...array];
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [
          shuffledArray[j],
          shuffledArray[i]
        ];
      }
    } while (JSON.stringify(shuffledArray) === JSON.stringify(array)); // 원본과 동일하면 재셔플

    return shuffledArray;
  },
  // 이메일 유효성 검사 함수
  validateEmail: (email: string): string | null => {
    if (!email) {
      return "이메일은 필수 입력 항목입니다.";
    } else if (!email.includes("@")) {
      return "이메일 주소에 '@'를 포함해 주세요.";
    } else if (email.split("@")[1].trim() === "") {
      return "'@' 뒷 부분을 입력해 주세요.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i.test(email)) {
      return "올바른 이메일 형식을 입력해 주세요.";
    }
    return null;
  },
  // 비밀번호 유효성 검사 함수
  validatePassword: (password: string): string | null => {
    if (!password) {
      return "비밀번호는 필수 입력 항목입니다.";
    } else if (password.length < 8) {
      return "비밀번호는 8자 이상이여야 합니다.";
    }
    return null;
  },
  // 사용자명 유효성 검사 함수
  validateProfileName: (name: string): string | null => {
    if (!name) {
      return "사용자명은 필수 입력 항목입니다.";
    } else if (name.length < 2) {
      return "사용자명은 2자 이상이여야 합니다.";
    } else if (name.length > 12) {
      return "사용자명은 12자 이하이여야 합니다.";
    }
    return null;
  },
  // 전화번호 유효성 검사 함수
  validatePhone: (phone: string): string | null => {
    if (!phone) {
      return "전화번호 항목을 입력해주세요.";
    } else if (
      !/^(010-[1-9]{1}\d{3}-[1-9]{1}\d{3})|((02|0[3-9]{1}[0-9]{1})-[1-9]{1}\d{2,3}-[1-9]{1}\d{3})$/.test(
        phone
      )
    ) {
      return "올바른 전화번호 형식(010-0000-0000)이 아닙니다.";
    }
    return null;
  },
  // 생년월일 유효성 검사 함수
  validateBirthday: (birthday: string): string | null => {
    if (!birthday) {
      return "생년월일 항목을 입력해주세요.";
    } else if (!/^\d{4}-\d{2}-\d{2}$/.test(birthday)) {
      return "올바른 날짜 형식(1980-01-01)으로 입력해 주세요.";
    } else if (!Utils.validateDate(birthday)) {
      return "올바른 날짜 입력 범위가 아닙니다.";
    }
    return null;
  }
};

export default Utils;
