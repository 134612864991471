import QuestionsSelectComponent from "../QuestionsSelectComponent";
import { ITrainingResponseRecord } from "@libs/common.types";
import ButtonsSubFilled from "@components/common/buttons/ButtonsSubFilled";

type ShortStoriesListeningQuizContentProps = {
  isSolvingQuiz: boolean;
  isCompleted: boolean;
  handleSolvingQuiz: () => void;
  handleReplayQuiz: () => void;
  trainingItemProgress: number;
  responses: ITrainingResponseRecord;
  message: string;
  answerList: string[];
  correctAnswer: string[];
  selectedAnswer: string[];
  setSelectedAnswer: React.Dispatch<React.SetStateAction<string[]>>;
  isQuestionPlay: boolean;
};

export default function ShortStoriesListeningQuizContent({
  isSolvingQuiz,
  isCompleted,
  handleSolvingQuiz,
  handleReplayQuiz,
  trainingItemProgress,
  responses,
  message,
  answerList,
  correctAnswer,
  selectedAnswer,
  setSelectedAnswer,
  isQuestionPlay
}: ShortStoriesListeningQuizContentProps) {
  return (
    <div className="flex flex-col justify-between items-center w-full h-36 lg:h-44">
      {!isSolvingQuiz && !isCompleted && (
        <>
          <p className="text-lg break-keep font-bold text-center lg:text-[32px]">
            아래 플레이(▶️) 버튼을 눌러 문장을 잘 들어보세요.
          </p>
          <p className="text-lg break-keep font-bold text-center lg:text-[32px]">
            잘 들으신 뒤 문장을 확인하세요.
          </p>
          <ButtonsSubFilled
            className="py-2 px-3 lg:text-2xl lg:p-4"
            type="button"
            mode="enable/icon"
            iconName="ico_question_mark"
            iconStyle="size-6 fill-mainColor1"
            text="퀴즈풀기"
            onClick={handleSolvingQuiz}
          />
        </>
      )}
      {isSolvingQuiz && !isCompleted && (
        <>
          <p className="text-lg font-bold lg:text-[32px] text-center break-keep">
            {message}
          </p>
          <QuestionsSelectComponent
            progress={trainingItemProgress}
            resp={responses}
            ansList={answerList}
            correctAnswer={correctAnswer}
            selAnswer={selectedAnswer}
            setSelectedAnswer={setSelectedAnswer}
            isCompleted={isCompleted}
          />
          <ButtonsSubFilled
            type="button"
            mode="enable/icon"
            text="다시듣기"
            className="py-2 px-3 lg:text-2xl lg:p-4"
            iconName={isQuestionPlay ? "ico_pause" : "ico_play"}
            iconStyle="size-6 fill-mainColor1"
            onClick={handleReplayQuiz}
          />
        </>
      )}
    </div>
  );
}
