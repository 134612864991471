import { useState } from "react";

import CustomerNavigation from "./CustomerNavigation";
import ProgramInputForm from "./ProgramInputForm";

// 고객관리 > 청능 재활 이력 컴포넌트

export default function RehabilitationHistory() {
  // 저장 완료 팝업 렌더링을 결정하는 상태
  // true면 저장 완료 팝업 렌더링
  const [isEditSuccess, setIsEditSuccess] = useState(false);

  const handleEditSuccess = () => {
    setIsEditSuccess((prev) => !prev);
  };

  return (
    <>
      <CustomerNavigation />

      <div className="flex justify-between items-center pb-8 px-4 border-b-2 border-gray-300 border-solid">
        {/** @description '레코드'를 의미하는 UI */}
        <div className="w-1/5 p-2 border-2 border-gray-300 border-solid text-center">
          {/** @todo 실제 데이터 구조에 따라 드롭다운 UI 렌더링 */}
          <select
            className="w-full text-center"
            name="date"
          >
            <option value="2024-09-01">2024년 09월 01일</option>
            <option value="2024-09-02">2024년 09월 02일</option>
            <option value="2024-09-03">2024년 09월 03일</option>
            <option value="2024-09-04">2024년 09월 04일</option>
            <option value="2024-09-05">2024년 09월 05일</option>
          </select>
        </div>
        {/** @todo 기록 추가 시 레코드 생성 기능 구현 */}
        <button className="basis-48 py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl">
          추가
        </button>
      </div>

      <section className="grid grid-cols-2 grid-rows-2 gap-8">
        <ProgramInputForm />
        <ProgramInputForm />
        <ProgramInputForm />
        <ProgramInputForm />
      </section>

      <div className="flex justify-center">
        <button
          className="basis-48 py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl"
          onClick={handleEditSuccess}
        >
          저장
        </button>
      </div>

      {/** @description '수정 완료 팝업' */}
      {isEditSuccess && (
        <div
          className="fixed top-0 left-0 w-screen h-screen bg-black/30"
          onClick={handleEditSuccess}
        >
          <div
            className="fixed top-[calc(50%_-_93px)] left-[calc(50%_-_246px)] flex flex-col justify-center items-center gap-8 w-1/3 h-1/5 p-8 bg-white border-2 border-gray-300 border-solid"
            onClick={(e) => e.stopPropagation()}
          >
            <p>변경한 내용이 저장되었습니다.</p>
            <button
              className="w-48 py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl"
              onClick={handleEditSuccess}
            >
              확인
            </button>
          </div>
        </div>
      )}
    </>
  );
}
