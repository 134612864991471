import Chips from "@components/common/chips/Chips";
import React from "react";
import { ReactSVG } from "react-svg";

type BottomSheetProps = {
  speed: number;
  noiseType?: string;
  noiseLevel?: number;
  noiseTypeMap?: { [key: string]: string }; // 매핑 객체
  handleShowSettings: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export default function BottomSheet({
  speed,
  noiseType,
  noiseLevel,
  noiseTypeMap,
  handleShowSettings
}: BottomSheetProps) {
  return (
    <div
      className="fixed bottom-0 flex justify-between items-center gap-2 w-full h-14 p-3 shadow-[0px_-4px_6px_0px_#00000029] rounded-t-[20px] bg-white max-w-[600px] cursor-pointer max-sm:gap-1 lg:h-[90px] lg:py-4 lg:px-5 lg:gap-6 lg:max-w-[1200px]"
      onClick={handleShowSettings}
    >
      <Chips text={`빠르기 ${speed + 1}`} />
      {noiseLevel !== undefined && noiseType && noiseTypeMap && (
        <>
          <Chips
            text={
              noiseTypeMap && noiseType
                ? (noiseTypeMap[noiseType] as string)
                : ""
            }
          />
          <Chips text={`소음크기 ${(noiseLevel as number) + 1}`} />
        </>
      )}
      <div className="flex justify-center items-center gap-0.5 text-sm text-center sm:w-2/3 lg:text-2xl lg:gap-2">
        <ReactSVG
          className="size-[18px] lg:size-8"
          src={`${process.env.PUBLIC_URL}/images/icons_update/ico_setting.svg`}
        />
        <p>설정</p>
      </div>
    </div>
  );
}
