import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import { useRecoilValue } from "recoil";

import { AuthError } from "@supabase/supabase-js";

import ButtonsMainFilled1 from "@components/common/buttons/ButtonsMainFilled1";
import Alert from "@components/common/Alert";
import TextFieldMain from "@components/common/textfield/TextfieldMain";
import MainFooter from "@components/common/MainFooter";

import useAuth from "@hooks/useAuth";

import type { AuthInputType } from "@libs/common.types";

import { UserAuthState } from "@states/UserAuthState";
import { ReactSVG } from "react-svg";

export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();
  const uaState = useRecoilValue(UserAuthState);

  const [emailErrorMessage, setEmailErrorMessage] = useState<
    string | undefined | null
  >();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<
    string | undefined | null
  >();
  const [alertMessage, setAlertMessage] = useState<string | null>(null); // Alert 메시지 상태

  useEffect(() => {
    console.log(uaState);
    if (uaState.user && uaState.profile && uaState.isAuthenticated) {
      navigate("/main");
    }
  }, [uaState.user && uaState.profile && uaState.isAuthenticated, navigate]);

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { isSubmitting }
  } = useForm<AuthInputType>();

  /** @todo 로그인 오류 팝업 컴포넌트 구현 */
  const onSubmit: SubmitHandler<AuthInputType> = async (data) => {
    console.log("onSubmit", data);
    setEmailErrorMessage(null);
    setPasswordErrorMessage(null);
    try {
      const result = await auth.signIn(data);
      console.log("onSubmit::result", result);
    } catch (error) {
      const err = error as AuthError | Error;
      console.error("onSubmit::error", err.message);
      if (err.message === "Email not confirmed") {
        setAlertMessage("이메일 인증을 완료해주세요.");
      } else {
        setAlertMessage(
          "로그인 오류가 발생하였습니다.\n이메일 또는 비밀번호를 확인하여 주세요."
        );
      }
      // TODO : more specific error exception handle
    }
  };

  const onError: SubmitErrorHandler<AuthInputType> = (error) => {
    if (Object.keys(error).includes("email")) {
      setEmailErrorMessage(error.email?.message);
      setPasswordErrorMessage(null);
      setFocus("email");
    } else if (Object.keys(error).includes("password")) {
      setEmailErrorMessage(null);
      setPasswordErrorMessage(error.password?.message);
      setFocus("password");
    }
  };

  /**
   * 이메일 입력 필드의 유효성 검사를 수행하고, 특정 조건에 따라 커스텀 오류 메시지를 설정
   */
  const handleEmailInvalid = (e: React.InvalidEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    if (!value.includes("@")) {
      setEmailErrorMessage("이메일 주소에 '@'를 포함해 주세요.");
    } else if (value.split("@")[1].trim() === "") {
      setEmailErrorMessage("'@' 뒷 부분을 입력해 주세요.");
    } else {
      setEmailErrorMessage("올바른 이메일 형식을 입력해 주세요.");
    }
  };

  return (
    <>
      {/* Alert 컴포넌트 사용 */}
      {alertMessage && (
        <Alert
          message={alertMessage}
          onClose={() => setAlertMessage(null)}
        />
      )}
      <div className="flex flex-col justify-center items-center w-screen h-dvh leading-none bg-login-gradient responsive-padding">
        <div className="relative flex flex-col justify-center items-center gap-6 responsive-max-width lg:gap-12">
          <div className="size-full flex justify-center items-center">
            <ReactSVG
              className="size-[118px] lg:size-[154px]"
              src={`${process.env.PUBLIC_URL}/images/logo/logo.svg`}
              // onClick="nextPage(0, 1);"
            />
          </div>
          <div className="flex flex-col justify-center items-center w-full bg-white rounded-3xl p-5 shadow-[0px_4px_20px_0px_#00000033] max-w-[600px]">
            <form
              id="loginForm"
              className="flex flex-col gap-3 w-full lg:gap-4"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <div className="flex flex-col gap-3 lg:gap-4">
                <p className="text-lg font-bold">이메일</p>
                <TextFieldMain
                  id="email"
                  type="email"
                  placeholder="이메일을 입력해 주세요"
                  iconName="ico_email"
                  errorMessage={emailErrorMessage}
                  register={register("email", {
                    required: "이메일은 필수 입력 항목입니다.",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                      message: "이메일 형식이 아닙니다."
                    }
                  })}
                  onInvalid={handleEmailInvalid}
                  onInput={() => setEmailErrorMessage(null)}
                />
                {emailErrorMessage && (
                  <p className="text-red80">{emailErrorMessage}</p>
                )}
              </div>
              <div className="flex flex-col gap-3 lg:gap-4">
                <p className="text-lg font-bold">비밀번호</p>
                <TextFieldMain
                  id="password"
                  type="password"
                  placeholder="비밀번호를 입력해 주세요"
                  iconName="ico_password"
                  errorMessage={passwordErrorMessage}
                  register={register("password", {
                    required: "비밀번호는 필수 입력 항목입니다.",
                    minLength: {
                      value: 8,
                      message: "비밀번호는 8자 이상이여야 합니다."
                    }
                  })}
                  onInput={() => setPasswordErrorMessage(null)}
                />
                {passwordErrorMessage && (
                  <p className="text-red80">{passwordErrorMessage}</p>
                )}
              </div>
              {!isSubmitting ? (
                <ButtonsMainFilled1
                  className="p-5"
                  type="submit"
                  mode="enable"
                  text="로그인"
                />
              ) : (
                <button
                  disabled
                  type="button"
                  className="w-full text-center text-black text-2xl"
                >
                  로그인중...
                </button>
              )}
              {/* 
                // TODO: 비밀번호 찾기 기능 추가 필요
                <div>
                  <span>
                    <Link to="/">비밀번호를 잊으셨습니까?</Link>
                  </span>
                </div> 
                */}
              <Link
                className="w-full text-center text-gray80"
                to="/signup"
                state={{ from: "/login" }}
              >
                계정만들기
              </Link>
            </form>
          </div>
        </div>
        <MainFooter />
      </div>
    </>
  );
}
