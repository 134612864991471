import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";

import dayjs from "dayjs";
import anime from "animejs";

import ButtonsMainOutlined from "@components/common/buttons/ButtonsMainOutlined";
import MainFooter from "@components/common/MainFooter";

import useAuth from "@hooks/useAuth";

import { UserAuthState } from "@states/UserAuthState";
import { ReactSVG } from "react-svg";
import LogoAnimation from "@components/\bLogoAnimation";
import Alert from "@components/common/Alert";

export default function Main() {
  const { user, profile, isAuthenticated } = useRecoilValue(UserAuthState);
  const [lastLogin, setLastLogin] = useState<string>("");
  const [isAnimateEnd, setIsAnimateEnd] = useState(false);
  const [isSignedOut, setIsSignedOut] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (user) {
      setLastLogin(dayjs(user.last_sign_in_at).format("YYYY-MM-DD HH:mm:ss"));
    }
  }, [user]);

  const handleAnimationEnd = () => {
    setIsAnimateEnd(true); // 메인 콘텐츠 표시

    // requestAnimationFrame을 사용하여 DOM이 완전히 렌더링된 후 애니메이션 시작
    requestAnimationFrame(() => {
      startMainAnimations();
    });
  };

  const startMainAnimations = () => {
    const logo = document.querySelector(".speechear-logo");
    const logoImage = document.querySelector(".speechear-logo > div");
    const buttons = document.querySelectorAll(".fade-in-button");

    anime({
      targets: logo,
      opacity: [0, 1],
      duration: 500,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: logo,
          translateY: -96,
          duration: 300,
          easing: "easeInOutQuad",
          delay: 500
        });
        if (window.innerWidth < 1280) {
          anime({
            targets: logoImage,
            width: ["140px", "118px"],
            duration: 300,
            easing: "easeInOutQuad",
            delay: 500
          });
        }
        anime({
          targets: buttons,
          opacity: [0, 1],
          duration: 300,
          easing: "easeInOutQuad",
          delay: 500,
          begin: () => {
            buttons.forEach((button) => {
              (button as HTMLElement).style.pointerEvents = "none";
            });
          },
          complete: () => {
            buttons.forEach((button) => {
              (button as HTMLElement).style.pointerEvents = "auto";
            });
          }
        });
      }
    });
  };

  // const handleSignout = async () => {
  //   const result = await auth.signOut();
  //   if (result) {
  //     alert("로그아웃 되었습니다.");
  //     window.location.reload();
  //   }
  // };

  const handleSignout = async () => {
    const result = await auth.signOut();
    if (result) {
      setIsSignedOut(true); // 로그아웃 상태 설정
    }
  };

  return (
    <div className="flex flex-col justify-center items-center w-screen h-dvh leading-none bg-login-gradient responsive-padding">
      {isSignedOut && (
        <Alert
          message="로그아웃 되었습니다."
          onClose={() => {
            setIsSignedOut(false);
            window.location.reload();
          }}
        />
      )}
      <div className="responsive-max-width">
        {!isAnimateEnd && <LogoAnimation onAnimationEnd={handleAnimationEnd} />}
        {isAnimateEnd && (
          <>
            <div className="speechear-logo size-full flex justify-center items-center opacity-0">
              <ReactSVG
                className="size-[140px] lg:size-[154px]"
                src={`${process.env.PUBLIC_URL}/images/logo/logo.svg`}
                // onClick="nextPage(0, 1);"
              />
            </div>
            <div className="flex items-center flex-col justify-center">
              {isAuthenticated ? (
                <>
                  <div className="absolute text-white text-[0.8em] font-bold leading-4 text-center w-screen top-4">
                    <p>:: 로그인정보 ::</p>
                    <p>
                      {profile && profile.name}({user.email}) 님 접속중입니다.
                    </p>
                    <p>마지막 접속: {lastLogin}</p>
                  </div>
                  <div className="flex flex-col gap-5 w-full max-w-[600px] text-center -translate-y-24">
                    <ButtonsMainOutlined
                      className="fade-in-button opacity-0 p-5 bg-white"
                      type="link"
                      mode="enable"
                      text="말귀 시작"
                      to="/login"
                    />
                    <ButtonsMainOutlined
                      className="fade-in-button opacity-0 p-5 bg-white"
                      type="button"
                      mode="enable"
                      text="로그아웃"
                      onClick={handleSignout}
                    />
                  </div>
                </>
              ) : (
                <div className="flex flex-col gap-5 w-full max-w-[600px] text-center -translate-y-24">
                  <ButtonsMainOutlined
                    className="fade-in-button opacity-0 p-5 bg-white"
                    type="link"
                    mode="enable"
                    text="로그인"
                    to="/login"
                  />
                  <ButtonsMainOutlined
                    className="fade-in-button opacity-0 p-5 bg-white"
                    type="link"
                    mode="enable"
                    text="회원가입"
                    to="/signup"
                    state={{ from: "/" }}
                  />
                </div>
              )}
            </div>
          </>
        )}
        <MainFooter />
      </div>
    </div>
  );
}
