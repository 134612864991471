type ChipsProps = {
  text: string;
};

export default function Chips({ text }: ChipsProps) {
  return (
    <div className="w-full p-2 bg-gray20 text-gray70 rounded-[100px] text-sm text-center max-sm:flex-1 lg:py-3 lg:px-4 lg:text-2xl">
      {text}
    </div>
  );
}
