import { useMemo } from "react";

export default function useBackgroundUrl(
  p_code: string,
  code: string,
  isCompleted: boolean
) {
  const backgroundUrl = useMemo(
    () =>
      `${
        process.env.PUBLIC_URL
      }/images/backgrounds/${p_code.toLowerCase()}/${code.toLowerCase()}.jpg`,
    [p_code, code]
  );

  return backgroundUrl;
}
