import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ManagerItemType } from "@libs/common.types";

import useManager from "@hooks/useManager";

import Pagination from "@components/manager/Pagination";
import ManagerForm from "./ManagerForm";

import managerData from "@data/managerData.json";

export default function ManagersMain() {
  // 커스텀 훅에서 날짜 형식 포맷 함수 사용
  const { formatDate } = useManager();

  // 고객등록 버튼 클릭 여부를 저장하는 상태, true면 고객 등록 컴포넌트 렌더링
  const [isRegistering, setIsRegistering] = useState(false);

  // 임시 고객 리스트 데이터를 저장하는 상태
  const [managerListData, setManagerListData] = useState<ManagerItemType[]>();

  // 등록일을 오늘로 설정
  const registrationDate = formatDate(new Date());

  // 고객등록 절차 시작(고객등록 버튼 클릭 시)
  const handleRegistering = () => {
    setIsRegistering((prev) => !prev);
  };

  // 임시 고객 리스트 데이터 불러오기
  useEffect(() => {
    setManagerListData(managerData.data);
  }, []);

  // 매니저 관리 > 리스트 > 관리자등록 컴포넌트에서 사용할 임시 데이터
  const { serialNumber, account, name, phone, category, status, memo } =
    managerData.data[0];

  return (
    <>
      {!isRegistering && managerListData ? (
        /** @todo 컴포넌트로 분리 */
        <>
          <div className="flex justify-between items-center">
            {/** @todo 전체 관리자 수 받아오기 */}
            <div className="flex gap-8">
              <p>총 125명의 관리자</p>
              <p className="text-green-600">기본정렬: 등록일 최신 순</p>
            </div>
            <div className="flex gap-8">
              <button
                className="basis-auto py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl"
                onClick={handleRegistering}
              >
                관리자등록
              </button>
            </div>
          </div>
          <table
            className="flex-1 table-fixed border-collapse text-center border-b-2 border-gray-300 border-solid"
            border={1}
            cellPadding={10}
            cellSpacing={10}
          >
            <thead className="border-y-2 border-gray-300 border-solid">
              <tr>
                <th className="p-4">이름</th>
                <th className="p-4">로그인계정</th>
                <th className="p-4">등록일</th>
              </tr>
            </thead>
            <tbody>
              {managerListData &&
                managerListData.map((item) => (
                  <tr key={item.index}>
                    <td className="align-middle p-4">
                      <Link
                        className="block h-inherit underline underline-offset-4"
                        to={`info/${item.index}`}
                      >
                        {item.name}
                      </Link>
                    </td>
                    <td className="align-middle p-4">{item.account}</td>
                    <td className="align-middle p-4">{item.register_date}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          {/** @todo 페이지네이션 기능 구현 */}
          <Pagination />
        </>
      ) : (
        <ManagerForm
          managerData={{
            serialNumber,
            registrationDate,
            account,
            name,
            phone,
            category,
            status,
            memo
          }}
          onSubmit={handleRegistering}
        />
      )}
    </>
  );
}
