import React from "react";

type HomeSectionType = {
  sectionTitle: string;
  sectionGuideText: string;
  children: React.ReactNode;
};

export default function HomeSection({
  sectionTitle,
  sectionGuideText,
  children
}: HomeSectionType) {
  return (
    <section className="w-full flex flex-col gap-1 justify-center items-center">
      <p className="text-black text-xl lg:text-[32px] leading-none font-bold">
        {sectionTitle}
      </p>
      <p className="text-gray60 text-sm lg:text-xl mb-5 lg:mb-10">
        {sectionGuideText}
      </p>
      {children}
      {!(sectionTitle === "훈련별 듣기") && (
        <hr className="w-full border-t border-solid border-gray20 my-8 lg:my-10" />
      )}
    </section>
  );
}
