import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { CommonButtonsProps } from "src/types/props.types";

export default function ButtonsMainOutlined({
  className,
  type,
  mode,
  text,
  to,
  onClick,
  state,
  iconName,
  iconStyle
}: CommonButtonsProps) {
  const btnStyle = mode.includes("enable")
    ? "border-mainColor1 text-mainColor1"
    : mode.includes("disable")
    ? "border-gray50 text-gray50 bg-white"
    : "";

  return type === "button" ? (
    <button
      className={`buttons-main-outlined ${
        mode.includes("icon")
          ? "flex justify-center items-center gap-[10px]"
          : ""
      } ${btnStyle} ${className || ""}`}
      type="button"
      onClick={onClick}
    >
      {mode.includes("icon") && (
        <ReactSVG
          className={iconStyle}
          src={`${process.env.PUBLIC_URL}/images/icons_update/${iconName}.svg`}
        />
      )}
      {text}
    </button>
  ) : (
    <Link
      to={to as string}
      className={`${className} buttons-main-outlined ${btnStyle}`}
      state={state}
    >
      {text}
    </Link>
  );
}
