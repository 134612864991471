import { Routes, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Main from "@pages/Main";
import Login from "@pages/Login";
import SignUp from "@pages/SignUp";

export default function AnimatedRoutes() {
  const location = useLocation();

  const isAnimating =
    (location.pathname === "/signup" && location.state?.from === "/login") ||
    (location.pathname === "/signup" && location.state?.from === "/");

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames={isAnimating ? "page" : ""}
        timeout={300}
      >
        <Routes location={location}>
          <Route
            path="/"
            element={<Main />}
          />
          <Route
            path="/signup"
            element={<SignUp />}
          />
          <Route
            path="/login"
            element={<Login />}
          />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}
