import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";

import { UserAuthState } from "@states/UserAuthState";
import Utils from "@libs/utils";
import type { ProfileDataType } from "@libs/common.types";

import Alert from "@components/common/Alert";

import useAuth from "@hooks/useAuth";
import { ReactSVG } from "react-svg";
import TextFieldMain from "@components/common/textfield/TextfieldMain";
import ButtonsMainOutlined from "@components/common/buttons/ButtonsMainOutlined";
import ButtonsMainFilled1 from "@components/common/buttons/ButtonsMainFilled1";
import NavigationMain from "@components/common/NavigationMain";

export default function MyPage() {
  const [authSession, setAuthSession] = useRecoilState(UserAuthState);
  const { user, profile } = useRecoilValue(UserAuthState);
  const auth = useAuth();
  const [alertMessage, setAlertMessage] = useState<string | null>(null); // Alert 메시지 상태
  const [profileErrorMessage, setProfileErrorMessage] = useState<
    string | undefined | null
  >();
  const [phoneErrorMessage, setPhoneErrorMessage] = useState<
    string | undefined | null
  >();
  const [dateErrorMessage, setDateErrorMessage] = useState<
    string | undefined | null
  >();
  const [buttonMode, setButtonMode] = useState<
    "disable/icon" | "enable" | "disable" | "enable/icon"
  >("disable/icon"); // 버튼 모드 상태

  // input 변경값 추적
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    control,
    trigger
  } = useForm<ProfileDataType>({
    defaultValues: {
      name: profile.name,
      phone: profile.phone,
      birthday: profile.birthday,
      gender: profile.gender
    }
  });

  const watchedValues = useWatch({ control });

  useEffect(() => {
    const isChanged =
      watchedValues.name !== profile.name ||
      watchedValues.phone !== profile.phone ||
      watchedValues.birthday !== profile.birthday ||
      watchedValues.gender !== profile.gender;

    setButtonMode(isChanged ? "enable/icon" : "disable/icon");
  }, [watchedValues, profile]);
  //

  const onSubmit: SubmitHandler<ProfileDataType> = async (data) => {
    console.log("onSubmit", data);
    const profile = await auth.updateProfile(user.id, data);
    setAuthSession({
      ...authSession,
      profile: profile
    });
    setAlertMessage("사용자 정보가 성공적으로 수정되었습니다.");
  };

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Utils.changeDate(event.target.value);
    setValue("birthday", newValue);
    trigger("birthday");
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Utils.changePhone(event.target.value);
    setValue("phone", newValue);
    trigger("phone");
  };

  const handleInput = (
    field: keyof ProfileDataType,
    errorSetter: React.Dispatch<React.SetStateAction<string | undefined | null>>
  ) => {
    return async () => {
      const isValid = await trigger(field);
      if (!isValid) {
        errorSetter(errors[field]?.message || null);
      } else {
        errorSetter(null);
      }
    };
  };

  // 휴대폰 번호 입력 시 자동 하이픈 추가(010-0000-0000)
  const handleInputPhone = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.currentTarget;
    let value = input.value.replace(/[^0-9]/g, "");
    if (value.length > 3 && value.length <= 7) {
      value = value.replace(/(\d{3})(\d+)/, "$1-$2");
    } else if (value.length > 7) {
      value = value.replace(/(\d{3})(\d{4})(\d+)/, "$1-$2-$3");
    }
    input.value = value;
  };

  // 생년월일 입력 시 자동 하이픈 추가(2000-01-01)
  const handleInputDate = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.currentTarget;
    let value = input.value.replace(/[^0-9]/g, "");
    if (value.length > 4 && value.length <= 6) {
      value = value.replace(/(\d{4})(\d+)/, "$1-$2");
    } else if (value.length > 6) {
      value = value.replace(/(\d{4})(\d{2})(\d+)/, "$1-$2-$3");
    }
    input.value = value;
  };

  // const logout = async () => {
  //   const result = await auth.signOut();
  //   alert("로그아웃 되었습니다.");
  //   navigate("/");
  // };

  return (
    <>
      {/* Alert 컴포넌트 사용 */}
      {alertMessage && (
        <Alert
          message={alertMessage}
          onClose={() => setAlertMessage(null)}
        />
      )}
      <main className="w-screen h-dvh responsive-padding mt-14 lg:mt-16">
        <div className="flex flex-col justify-center items-center gap-5 responsive-max-width mx-auto">
          <NavigationMain
            property="depth"
            elType="link"
            to="/main"
            title="회원정보"
            bgColor="white"
          />

          <form
            id="updateProfileForm"
            className="flex flex-col gap-6 w-full lg:gap-9 lg:max-w-[600px] mt-6 lg:mt-9"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-3 lg:gap-4">
              <p className="text-lg font-bold">이메일</p>
              <TextFieldMain
                id="email"
                type="email"
                placeholder="이메일을 입력해 주세요"
                iconName="ico_email"
                value={user.email}
                disabled
              />
            </div>
            <div className="flex flex-col gap-3 lg:gap-4">
              <p className="text-lg font-bold">사용자명</p>
              <TextFieldMain
                id="name"
                type="text"
                placeholder="사용자명을 입력해 주세요"
                iconName="ico_profile"
                value={profile.name}
                errorMessage={profileErrorMessage}
                register={register("name", {
                  required: "사용자명은 필수 입력 항목입니다.",
                  minLength: {
                    value: 2,
                    message: "사용자명은 2자 이상이여야 합니다."
                  },
                  maxLength: {
                    value: 12,
                    message: "사용자명은 12자 이하이여야 합니다."
                  },
                  onChange: handleInput("name", setProfileErrorMessage)
                })}
              />
              {profileErrorMessage && (
                <p className="text-red80">{profileErrorMessage}</p>
              )}
            </div>
            <div className="flex flex-col gap-3 lg:gap-4">
              <p className="text-lg font-bold">전화번호</p>
              <TextFieldMain
                id="phone"
                type="text"
                placeholder="전화번호를 입력해 주세요"
                iconName="ico_call"
                value={profile.phone}
                errorMessage={phoneErrorMessage}
                register={register("phone", {
                  required: "전화번호 항목을 입력해주세요.",
                  pattern: {
                    value:
                      /^(010-[1-9]{1}\d{3}-[1-9]{1}\d{3})|((02|0[3-9]{1}[0-9]{1})-[1-9]{1}\d{2,3}-[1-9]{1}\d{3})$/,
                    message: "올바른 전화번호 형식(010-0000-0000)이 아닙니다."
                  },
                  onChange: handleInput("phone", setPhoneErrorMessage)
                })}
                onInput={handleInputPhone}
                onChange={handleChangePhone}
              />
              {phoneErrorMessage && (
                <p className="text-red80">{phoneErrorMessage}</p>
              )}
            </div>
            <div className="flex flex-col gap-3 lg:gap-4">
              <p className="text-lg font-bold">생일</p>
              <TextFieldMain
                id="birthday"
                type="text"
                placeholder="생년월일을 입력해 주세요"
                iconName="ico_calendar"
                value={profile.birthday}
                errorMessage={dateErrorMessage}
                register={register("birthday", {
                  required: "생년월일 항목을 입력해주세요.",
                  pattern: {
                    value: /^\d{4}-\d{2}-\d{2}$/,
                    message: "올바른 날짜 형식(1980-01-01)으로 입력해 주세요."
                  },
                  validate: (fieldValue: string | undefined) =>
                    Utils.validateDate(fieldValue) ||
                    "올바른 날짜 입력 범위가 아닙니다.",
                  onChange: handleInput("birthday", setDateErrorMessage)
                })}
                onInput={handleInputDate}
                onChange={handleChangeDate}
              />
              {dateErrorMessage && (
                <p className="text-red80">{dateErrorMessage}</p>
              )}
            </div>

            <div className="flex flex-col gap-3 lg:gap-4">
              <p className="text-lg font-bold">성별</p>
              <div className="textfield-main border border-solid border-gray50 text-black hover:border-mainColor1">
                <ReactSVG
                  className={`absolute h-6 w-6 left-3 top-1/2 transform -translate-y-1/2 z-10 fill-gray50`}
                  src={`${process.env.PUBLIC_URL}/images/icons_update/ico_profile.svg`}
                />
                <select
                  id="gender"
                  defaultValue={profile.gender}
                  className="w-full bg-white"
                  {...register("gender")}
                >
                  <option value="M">남성</option>
                  <option value="F">여성</option>
                </select>
              </div>
            </div>

            <div className="flex gap-3 w-full mb-[72px]">
              <ButtonsMainOutlined
                className="p-3"
                type="button"
                mode={buttonMode}
                text="되돌리기"
                iconName="ico_refresh"
                iconStyle={`${
                  buttonMode === "disable/icon"
                    ? "fill-gray50"
                    : "fill-mainColor1"
                }`}
                onClick={(e) => {
                  buttonMode === "disable/icon"
                    ? e.preventDefault()
                    : window.location.reload();
                }}
              />
              <ButtonsMainFilled1
                className="p-3"
                type="submit"
                mode={buttonMode}
                text="저장하기"
                iconName="ico_check"
                iconStyle={`w-6 h-6 ${
                  buttonMode === "disable/icon" ? "fill-gray50" : "fill-white"
                }`}
                onClick={(e) => {
                  if (buttonMode === "disable/icon") {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </form>
        </div>
      </main>
    </>
  );
}
