import { TrainingType } from "./trainingType";

const MAIN = "main";
const TRAINING = "training";
const LISTENING_BY_TRAINING = "listening_by_training";
const TODAY = "today";
const YESTERDAY = "yesterday";
const CROSSWORD = `${TRAINING}/part5`;
const CATEGORY = "category";

// already include "/main", use only LayoutRoutes.tsx
export const LAYOUT_ROUTES = {
  DAILY_TRAINING: `/${TRAINING}`,
  TODAY: `/${TRAINING}/${TODAY}`,
  YESTERDAY: `/${TRAINING}/${YESTERDAY}`,
  LISTENING_BY_TRAINING: `/${TRAINING}/${LISTENING_BY_TRAINING}`,
  LISTEN_IN_NOISE: `/${TRAINING}/:day?/${TrainingType.LISTEN_IN_NOISE.toLowerCase()}/:itemName`,
  SHORT_STORY: `/${TRAINING}/:day?/${TrainingType.SHORT_STORY.toLowerCase()}/:itemName`,
  LONG_STORY: `/${TRAINING}/:day?/${TrainingType.LONG_STORY.toLowerCase()}/:itemName`,
  ORDERING_SENTENCE: `/${TRAINING}/:day?/${TrainingType.ORDERING_SENTENCE.toLowerCase()}/:itemName`,
  CATEGORY: `/${CATEGORY}`,
  CROSSWORD: `/${CROSSWORD}`
};

const MAIN_TODAY = `/${MAIN}/${TRAINING}/${TODAY}`;
const MAIN_YESTERDAY = `/${MAIN}/${TRAINING}/${YESTERDAY}`;
const MAIN_LISTENING_BY_TRAINING = `/${MAIN}/${TRAINING}/${LISTENING_BY_TRAINING}`;

export const TODAY_ROUTES = {
  MAIN: `${MAIN_TODAY}`,
  LISTEN_IN_NOISE: `${MAIN_TODAY}/${TrainingType.LISTEN_IN_NOISE.toLowerCase()}`,
  SHORT_STORY: `${MAIN_TODAY}/${TrainingType.SHORT_STORY.toLowerCase()}`,
  LONG_STORY: `${MAIN_TODAY}/${TrainingType.LONG_STORY.toLowerCase()}`,
  ORDERING_SENTENCE: `${MAIN_TODAY}/${TrainingType.ORDERING_SENTENCE.toLowerCase()}`
};

export const YESTERDAY_ROUTES = {
  MAIN: `${MAIN_YESTERDAY}`,
  LISTEN_IN_NOISE: `${MAIN_YESTERDAY}/${TrainingType.LISTEN_IN_NOISE.toLowerCase()}`,
  SHORT_STORY: `${MAIN_YESTERDAY}/${TrainingType.SHORT_STORY.toLowerCase()}`,
  LONG_STORY: `${MAIN_YESTERDAY}/${TrainingType.LONG_STORY.toLowerCase()}`,
  ORDERING_SENTENCE: `${MAIN_YESTERDAY}/${TrainingType.ORDERING_SENTENCE.toLowerCase()}`
};

export const LISTENING_BY_TRAINING_ROUTES = {
  MAIN: `${MAIN_LISTENING_BY_TRAINING}`,
  LISTEN_IN_NOISE: `${MAIN_LISTENING_BY_TRAINING}/${TrainingType.LISTEN_IN_NOISE.toLowerCase()}`,
  SHORT_STORY: `${MAIN_LISTENING_BY_TRAINING}/${TrainingType.SHORT_STORY.toLowerCase()}`,
  LONG_STORY: `${MAIN_LISTENING_BY_TRAINING}/${TrainingType.LONG_STORY.toLowerCase()}`,
  ORDERING_SENTENCE: `${MAIN_LISTENING_BY_TRAINING}/${TrainingType.ORDERING_SENTENCE.toLowerCase()}`
};

export const ROUTES = {
  TODAY: TODAY_ROUTES,
  YESTERDAY: YESTERDAY_ROUTES,
  CROSSWORD: `/${MAIN}/${CROSSWORD}`,
  CATEGORY: `/${MAIN}/${CATEGORY}`
};
