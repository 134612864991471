import ButtonsPlayL from "@components/common/buttons/ButtonsPlayL";
import { ReactSVG } from "react-svg";

type QuizNavigationProps = {
  isCompleted: boolean;
  blinkNextButton: boolean;
  trainingItemProgress: number;
  handlePreviousQuestion: () => void;
  handleNextQuestion: () => void;
  isStartSolvingQuiz?: boolean;
  handlePlay: () => void;
  isPlay: boolean;
};

export default function QuizNavigation({
  trainingItemProgress,
  isCompleted,
  blinkNextButton,
  handlePreviousQuestion,
  handleNextQuestion,
  isStartSolvingQuiz,
  handlePlay,
  isPlay
}: QuizNavigationProps) {
  if (isStartSolvingQuiz) {
    return <div className="flex justify-between items-center"></div>;
  }

  return (
    <div className="flex justify-center w-full">
      <div className="flex justify-between w-full max-w-[414px]">
        <button
          className={`flex justify-center items-center gap-2 ${
            trainingItemProgress === 1 ? "cursor-not-allowed" : "cursor-pointer"
          } `}
          onClick={handlePreviousQuestion}
          disabled={trainingItemProgress === 1}
        >
          <ReactSVG
            className={`size-4 ${
              trainingItemProgress === 1 ? "fill-gray50" : "fill-gray80"
            }`}
            src={`${process.env.PUBLIC_URL}/images/icons_update/ico_left.svg`}
          />
          <span
            className={`text-sm ${
              trainingItemProgress === 1 ? "text-gray50" : "text-gray80"
            } lg:text-2xl`}
          >
            이전
          </span>
        </button>

        <ButtonsPlayL
          handlePlay={handlePlay}
          isPlay={isPlay}
        />

        <button
          className={`flex justify-center items-center gap-2 ${
            blinkNextButton ? "animate-blink" : ""
          }`}
          onClick={handleNextQuestion}
        >
          <span className="text-sm text-gray80 lg:text-2xl">다음</span>
          <ReactSVG
            className="size-4"
            src={`${process.env.PUBLIC_URL}/images/icons_update/ico_right.svg`}
          />
        </button>
      </div>
    </div>
  );
}
