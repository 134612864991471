export default function Pagination() {
  return (
    <div className="flex gap-4">
      <button className="flex-[2] p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        맨앞
      </button>
      <button className="flex-[2] p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        이전
      </button>
      <button className="flex-1 p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        1
      </button>
      <button className="flex-1 p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        2
      </button>
      <button className="flex-1 p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        3
      </button>
      <button className="flex-1 p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        4
      </button>
      <button className="flex-1 p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        5
      </button>
      <button className="flex-1 p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        6
      </button>
      <button className="flex-1 p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        7
      </button>
      <button className="flex-1 p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        8
      </button>
      <button className="flex-1 p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        9
      </button>
      <button className="flex-1 p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        10
      </button>
      <button className="flex-[2] p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        다음
      </button>
      <button className="flex-[2] p-2 bg-gray-300 border-2 border-black border-solid rounded-xl">
        맨뒤
      </button>
    </div>
  );
}
