import Manager from "@pages/manager/Manager";
import ManagerDetail from "@pages/manager/ManagerDetail";
import { Routes, Route } from "react-router-dom";

export default function ManagerRoutes() {
  return (
    <Routes>
      <Route
        path=":section"
        element={<Manager />}
      />
      <Route
        path=":section/:detail/:index"
        element={<ManagerDetail />}
      />
    </Routes>
  );
}
