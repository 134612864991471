const useManager = () => {
  // 날짜를 'YYYY. MM. DD.' 형식으로 포맷
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1 필요
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}. ${month}. ${day}.`;
  };

  // 연령(만 나이) 계산 함수
  function calculateAge(birthDateString: string) {
    // 생년월일 문자열을 배열로 변환 후 'Date' 객체 생성
    const [year, month, day] = birthDateString.split(".").map(Number);
    const birthDate = new Date(year, month - 1, day); // 월은 0부터 시작하므로 -1

    // 현재 날짜
    const today = new Date();

    // 나이 계산
    let age = today.getFullYear() - birthDate.getFullYear();

    // 생일이 지났는지 확인
    const isBirthdayPassed =
      today.getMonth() > birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() >= birthDate.getDate());

    // 생일이 지나지 않았다면 한 살 적음
    if (!isBirthdayPassed) {
      age--;
    }

    return age;
  }

  return { formatDate, calculateAge };
};

export default useManager;
