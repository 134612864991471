import { useParams } from "react-router-dom";

import AdminManagerLayout from "@components/common/AdminManagerLayout";
import ManagerInfo from "@components/admin/ManagerInfo";

export default function AdminDetail() {
  /**
   * 현재 세부 섹션 (개별 관리자)
   * @property {'info'} detail - 세부 섹션, 'info' 값을 가짐
   */
  const { detail } = useParams();

  return (
    <AdminManagerLayout>
      {detail === "info" && <ManagerInfo />}
    </AdminManagerLayout>
  );
}
