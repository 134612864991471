import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ManagerItemType } from "@libs/common.types";

import Pagination from "@components/manager/Pagination";

import managerData from "@data/managerData.json";
import ManagerCouponForm from "./ManagerCouponForm";
import CouponConfirmModal from "./CouponConfirmModal";
import CouponStatusTable from "./CouponStatusTable";

export default function ManagerInfo() {
  const { index } = useParams();

  const [managerListData, setManagerListData] = useState<ManagerItemType[]>();
  // 내역확인 상태 저장: true면 쿠폰 지급 내역 확인 화면 렌더링
  const [isHistoryChecked, setIsHistoryChecked] = useState(false);
  // 쿠폰지급 페이지 이동 여부 저장: true면 쿠폰 지급 페이지로 이동
  const [isCouponPageOpen, setIsCouponPageOpen] = useState(false);
  // 쿠폰 지급 확인 상태 저장: true면 지급 확인 alert 팝업 렌더링
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  // 쿠폰지급 상태 저장: true면 쿠폰 지급 완료
  const [isCouponIssued, setIsCouponIssued] = useState(false);

  // 임시 고객 리스트 데이터 불러오기
  useEffect(() => {
    setManagerListData(managerData.data);
  }, []);

  // 고객상세 > 수정 컴포넌트에서 사용할 임시 데이터
  const { register_date, account, name, phone, category, status, memo } =
    managerData.data[Number(index) - 1];
  const mappedStatus = status === "active" ? "활성화" : "비활성";

  // 내역확인 핸들러
  const handleHistoryChecked = () => {
    setIsHistoryChecked((prev) => !prev);
  };

  // 쿠폰지급 페이지 이동 핸들러
  const handleCouponPageOpen = () => {
    setIsCouponPageOpen((prev) => !prev);
  };

  // 쿠폰지급 핸들러
  const handleCouponIssued = () => {
    setIsCouponIssued(true); // 쿠폰 지급 완료
  };

  // 쿠폰지급 alert 핸들러
  const handleConfirmCouponIssued = () => {
    setIsConfirmModalOpen((prev) => !prev);
    setIsCouponIssued(false);
  };

  // 쿠폰지급 관련 상태 초기화 함수
  const resetCouponState = () => {
    setIsCouponPageOpen(false);
    setIsConfirmModalOpen(false);
    setIsCouponIssued(false);
  };

  if (!managerListData) {
    return null; // 데이터가 없을 경우 아무것도 렌더링하지 않음
  }

  return (
    <>
      {/** @desc 말귀 매니저 관리 > 리스트 > 매니저 상세 컴포넌트 렌더링 */}
      {!isHistoryChecked && !isCouponPageOpen && (
        <section className="flex flex-col h-full justify-between">
          <div className="grid grid-cols-4 gap-4 p-4 border border-gray-300">
            <div className="col-span-1 font-bold h-9">관리자명</div>
            <div className="col-span-2 h-9">{name}</div>
            <div className="col-span-1 text-right">
              <button className="w-1/2 py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl">
                수정
              </button>
            </div>

            <div className="col-span-1 font-bold h-9">
              회원계정
              <br />
              (이메일)
            </div>
            <div className="col-span-3 h-9">{account}</div>

            <div className="col-span-1 font-bold h-9">등록일</div>
            <div className="col-span-1 h-9">{register_date}</div>
            <div className="col-span-1 font-bold h-9">분류</div>
            <div className="col-span-1 h-9">{category}</div>

            <div className="col-span-1 font-bold h-9">연락처</div>
            <div className="col-span-1 h-9">{phone}</div>
            <div className="col-span-1 font-bold h-9">상태</div>
            <div className="col-span-1 h-9">{mappedStatus}</div>

            <div className="col-span-1 font-bold h-9">메모</div>
            <div className="col-span-3 h-9">{memo}</div>
          </div>

          {/** @desc 쿠폰 지급 현황 테이블 렌더링 */}
          <CouponStatusTable
            handleCouponPageOpen={handleCouponPageOpen}
            handleHistoryChecked={handleHistoryChecked}
          />

          {/** @desc 쿠폰 지급 현황 테이블 페이지네이션 UI 렌더링 */}
          <Pagination />
        </section>
      )}

      {/** @desc 지급 내역 확인 컴포넌트 렌더링 */}
      {isHistoryChecked && !isCouponPageOpen && (
        <ManagerCouponForm
          mode="history"
          managerData={{ name }}
          navigateToBack={handleHistoryChecked}
          onSubmit={handleHistoryChecked}
        />
      )}

      {!isHistoryChecked && isCouponPageOpen && (
        <>
          {/** @desc 지급 확인 alert 팝업 */}
          {isConfirmModalOpen && (
            <CouponConfirmModal
              isCouponIssued={isCouponIssued}
              handleCancel={handleConfirmCouponIssued}
              handleCouponIssued={handleCouponIssued}
              handleReset={resetCouponState}
            />
          )}

          {/** @desc 쿠폰 지급 컴포넌트(폼) 렌더링 */}
          <ManagerCouponForm
            mode="issued"
            managerData={{ name }}
            navigateToBack={handleCouponPageOpen}
            onSubmit={handleConfirmCouponIssued}
          />
        </>
      )}
    </>
  );
}
