import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import ListeningLinks from "@components/common/TrainingLinkList";

import { ROUTES } from "@constants/common.routes";

import { DailyTrainingState, ITrainingItem } from "@states/DailyTrainingState";
import { UserAuthState } from "@states/UserAuthState";

import useAppData from "@hooks/useAppData";

export default function DailyTrainingMain() {
  const [testAuth, setTestAuth] = useState(false);
  const [testIndex, setTestIndex] = useState(1);
  const [testMaxIndex, setMaxTestIndex] = useState(1);
  const authState = useRecoilValue(UserAuthState);
  const dtState = useRecoilValue(DailyTrainingState);
  const location = useLocation();
  const appData = useAppData();
  const { user, profile } = authState;
  let dtLogCount = dtState.dailyCount;

  let title = null;
  let dtItem = null;
  let BottomLinkComponent = () => <></>;

  /* 테스트: 오늘의 학습 셋 리스트 확인 */
  const selectIndexTrainingSet = async (index: number) => {
    await appData.getDailyTrainingSet(index);
    await appData.updateTrainingSet();
  };

  const nextIndex = () => {
    if (testIndex < 100) {
      if (testMaxIndex > testIndex) {
        setTestIndex(testIndex + 1);
      }
    }
  };

  const prevIndex = () => {
    if (testIndex > 1) {
      setTestIndex(testIndex - 1);
    }
  };

  // ROLE_ADMIN 계정일 경우 'testAuth'를 true로 설정
  useEffect(() => {
    if (profile && profile.role === "ROLE_ADMIN") {
      setTestAuth(true);
      appData.getMaxTrainingSetIndex().then((result) => {
        const maxIndex = result.data?.index || dtLogCount;
        // console.log("maxIndex", maxIndex);
        setMaxTestIndex(maxIndex);
      });
    }
  }, [user]);

  /* 테스트: END */

  if (location.pathname === ROUTES.TODAY.MAIN) {
    title = "오늘의 듣기";
    dtItem = dtState.today;
    BottomLinkComponent = () => (
      <Link
        to={ROUTES.YESTERDAY.MAIN}
        className="w-full p-5 text-center bg-mainColor1 rounded-[20px] font-bold text-white mt-3 lg:mt-0 lg:max-w-[600px] lg:text-2xl"
      >
        어제의 듣기 다시보기
      </Link>
    );
  } else if (location.pathname === ROUTES.YESTERDAY.MAIN) {
    title = "어제의 듣기";
    dtItem = dtState.yesterday;
    BottomLinkComponent = () => (
      <Link
        to={ROUTES.TODAY.MAIN}
        className="w-full p-5 text-center bg-mainColor1 rounded-[20px] font-bold text-white mt-3 lg:mt-0 lg:max-w-[600px] lg:text-2xl"
      >
        오늘의 듣기 다시보기
      </Link>
    );
    dtLogCount--;
  }

  const ListenLinksComponent = ({ dt }: { dt: ITrainingItem[] | null }) => {
    return (
      <>
        {dt &&
          dt.length > 0 &&
          dt.map((trainingItem: ITrainingItem) => {
            const { type, items } = trainingItem;
            return (
              <ListeningLinks
                key={type.key}
                type={type.key}
                items={items}
                categoryData={items.map((item) => ({
                  p_code: item.category_main_code,
                  code: item.category_sub_code
                }))}
              />
            );
          })}
      </>
    );
  };

  return (
    <>
      <main className="flex justify-center items-center w-full responsive-padding mt-14 mb-[60px] lg:mt-16">
        <div className="flex flex-col justify-center items-center gap-5 responsive-max-width lg:gap-10">
          <div className="flex flex-col justify-center items-center mt-4">
            <p className="text-2xl text-black font-bold mb-1 lg:text-[32px]">
              {title && title}
            </p>
            <p className="text-sm text-gray60 lg:text-xl">
              주제를 선택하여 듣기 연습을 진행하세요
            </p>
            <p className="text-sm py-1 px-2 mt-3 bg-gray20 rounded-[100px] lg:text-base">
              {dtLogCount}일차
            </p>
          </div>
          {testAuth && (
            // 테스트 계정일 경우만 이전, 다음 날짜로 이동 가능
            <div className="flex flex-col items-center justify-center">
              <p>TrainSetIdx: {testIndex}</p>
              <div className="flex gap-4">
                <p>
                  <button onClick={prevIndex}>[PREV]</button>
                </p>
                <p>
                  <button onClick={() => selectIndexTrainingSet(testIndex)}>
                    <b>[GO]</b>
                  </button>
                </p>
                <p>
                  <button onClick={() => selectIndexTrainingSet(dtLogCount)}>
                    <b>[RESET]</b>
                  </button>
                </p>
                <p>
                  <button onClick={nextIndex}>[NEXT]</button>
                </p>
              </div>
            </div>
          )}
          {dtState && <ListenLinksComponent dt={dtItem} />}
          <BottomLinkComponent />
        </div>
      </main>
    </>
  );
}
