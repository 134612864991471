import { ButtonsInputProps } from "src/types/props.types";

export default function ButtonsInput({ onGoToPage }: ButtonsInputProps) {
  return (
    <div className="flex justify-center items-center gap-[6px] py-2 px-[14px] h-10 rounded-[40px] border border-solid border-gray30 active:border-mainColor1 hover:border-mainColor1">
      <input
        type="text"
        className="size-6 border-b border-solid border-gray60 text-gray80 text-center focus:border-gray80 focus:outline-none [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        style={{ appearance: "textfield" }}
      />

      <button
        className="text-gray60 whitespace-nowrap active:text-mainColor1"
        onClick={() =>
          onGoToPage(
            parseInt(
              (document.querySelector("input[type=text]") as HTMLInputElement)
                .value,
              10
            )
          )
        }
      >
        페이지로 이동
      </button>
    </div>
  );
}
