import ButtonsSubFilled from "@components/common/buttons/ButtonsSubFilled";
import { ITrainingResponseRecord } from "@libs/common.types";

type QuestionBottomProps = {
  progress: number;
  resp: ITrainingResponseRecord;
  selAnswer: string[];
  handleAnswer: (answer: string[], progress: number) => void;
};

export default function QuestionBottom({
  progress,
  resp,
  selAnswer,
  handleAnswer
}: QuestionBottomProps) {
  const currentResp = resp[progress];

  if (!currentResp && progress === 1) {
    return (
      <ButtonsSubFilled
        type="button"
        mode="enable/icon"
        text="정답확인"
        className="py-2 px-3 lg:text-2xl lg:p-4"
        iconName="ico_check"
        iconStyle="size-6 fill-mainColor1"
        onClick={() => handleAnswer(selAnswer, progress)}
      />
    );
  } else {
    return <></>;
  }
}
