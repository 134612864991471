import {
  ITrainingResponseRecord,
  TrainingQuestionItemsType
} from "@libs/common.types";
import QuestionBottom from "../QuestionBottom";
import QuestionsMultiSelectComponent from "../QuestionsMultiSelectComponent";
import QuestionsSelectComponent from "../QuestionsSelectComponent";
import ButtonsSubFilled from "@components/common/buttons/ButtonsSubFilled";

type LongStoriesListeningQuizContentProps = {
  isCompleted: boolean;
  isSolvingQuiz: boolean;
  handleSolvingQuiz: () => void;
  currentQuestionItem: TrainingQuestionItemsType;
  questionItemProgress: number;
  responses: ITrainingResponseRecord;
  message: string;
  answerList: string[];
  correctAnswer: string[];
  selectedAnswer: string[];
  setSelectedAnswer: React.Dispatch<React.SetStateAction<string[]>>;
  handleAnswer: (answer: string[], progress: number) => void;
};

export default function LongStoriesListeningQuizContent({
  isCompleted,
  isSolvingQuiz,
  handleSolvingQuiz,
  currentQuestionItem,
  questionItemProgress,
  responses,
  message,
  answerList,
  correctAnswer,
  selectedAnswer,
  setSelectedAnswer,
  handleAnswer
}: LongStoriesListeningQuizContentProps) {
  return (
    /** @todo 보기 및 문제 렌더링 요소 높이 수정 */
    <div className="flex flex-col justify-center items-center gap-4 w-full h-48 lg:gap-5">
      {!isCompleted && !isSolvingQuiz && (
        <>
          <p className="text-lg break-keep font-bold text-center lg:text-[32px]">
            아래 플레이(▶️) 버튼을 눌러 문장을 잘 들어보세요.
          </p>
          <p className="text-lg break-keep font-bold text-center lg:text-[32px]">
            잘 들으신 뒤 문장을 확인하세요.
          </p>
          <ButtonsSubFilled
            className="py-2 px-3 lg:text-2xl lg:p-4"
            type="button"
            mode="enable/icon"
            iconName="ico_question_mark"
            iconStyle="size-6 fill-mainColor1"
            text="퀴즈풀기"
            onClick={handleSolvingQuiz}
          />
        </>
      )}
      {!isCompleted && isSolvingQuiz && currentQuestionItem && (
        <>
          <p className="text-lg font-bold lg:text-[32px] text-center break-keep">
            {message}
          </p>
          {currentQuestionItem.index === 1 ? (
            <QuestionsMultiSelectComponent
              progress={questionItemProgress}
              resp={responses}
              ansList={answerList}
              correctAnswer={correctAnswer}
              selAnswer={selectedAnswer}
              setSelectedAnswer={setSelectedAnswer}
              isCompleted={isCompleted}
            />
          ) : (
            <QuestionsSelectComponent
              progress={questionItemProgress}
              resp={responses}
              ansList={answerList}
              correctAnswer={correctAnswer}
              selAnswer={selectedAnswer}
              setSelectedAnswer={setSelectedAnswer}
              isCompleted={isCompleted}
            />
          )}

          <QuestionBottom
            progress={questionItemProgress}
            resp={responses}
            selAnswer={selectedAnswer}
            handleAnswer={handleAnswer}
          />
        </>
      )}
    </div>
  );
}
