export const NoiseTypes = {
  DEFAULT: "defaultNoise",
  STREET: "street",
  RESTAURANT: "restaurant",
  BUZZ: "buzz"
} as const;

export type NoiseType = (typeof NoiseTypes)[keyof typeof NoiseTypes];

export const NoiseTypeOptions = [
  { type: NoiseTypes.DEFAULT, label: "기본소음", icon: "default" },
  { type: NoiseTypes.STREET, label: "길거리", icon: "street" },
  { type: NoiseTypes.BUZZ, label: "와글와글", icon: "buzz" },
  { type: NoiseTypes.RESTAURANT, label: "식당", icon: "restaurant" }
];

export const noiseTypeMap: { [key: string]: string } = {
  defaultNoise: "기본소음",
  street: "길거리",
  buzz: "와글와글",
  restaurant: "식당"
};
