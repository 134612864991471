import CustomerNavigation from "./CustomerNavigation";

// 고객관리 > 쿠폰 컴포넌트

export default function Coupons() {
  return (
    <>
      <CustomerNavigation />

      <div className="flex justify-end items-center pb-8 px-4 border-b-2 border-gray-300 border-solid">
        {/** @todo 쿠폰 등록 기능 구현 */}
        <button className="basis-48 py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl">
          쿠폰 등록
        </button>
      </div>

      <section className="flex flex-col gap-12">
        <div className="flex">
          <p className="w-1/3 font-bold">고객 코드</p>
          <p>6T3mKweA</p>
        </div>
        <div className="flex">
          <p className="w-1/3 font-bold">현재 쿠폰 적용 여부</p>
          <p>YES</p>
        </div>
        <div className="flex">
          <p className="w-1/3 font-bold">이용권 만료일</p>
          <p>2024.09.30</p>
        </div>
        <div className="flex flex-col gap-4">
          <p className="font-bold">쿠폰 적용 이력</p>
          <table className="border-collapse table-fixed">
            <thead className="border-b-2 border-gray-300 border-solid">
              <tr>
                <th className="p-4">쿠폰 번호</th>
                <th className="p-4">종류</th>
                <th className="p-4">적용일</th>
                <th className="p-4">종료일</th>
                <th className="p-4">상태</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                <td className="p-4">ABC12345</td>
                <td className="p-4">30일</td>
                <td className="p-4">2024.08.31</td>
                <td className="p-4">2024.09.30</td>
                <td className="p-4">적용중</td>
              </tr>
              <tr className="text-center">
                <td className="p-4">ABC12345</td>
                <td className="p-4">90일</td>
                <td className="p-4">2024.06.02</td>
                <td className="p-4">2024.08.30</td>
                <td className="p-4">종료</td>
              </tr>
              <tr className="text-center">
                <td className="p-4">ABC12345</td>
                <td className="p-4">7일</td>
                <td className="p-4">2024.05.24</td>
                <td className="p-4">2024.06.01</td>
                <td className="p-4">종료</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
