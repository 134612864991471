import { useState } from "react";

import Calendar from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import "react-calendar/dist/Calendar.css";

import { CustomerFormProps } from "src/types/props.types";
import useManager from "@hooks/useManager";

// 고객관리 > 리스트 > 고객등록 | 고객관리 > 고객상세 > 기본정보 > 수정 시 UI

export default function CustomerForm({
  mode,
  customerData,
  onSubmit
}: CustomerFormProps) {
  // 커스텀 훅에서 날짜 형식 포맷 함수 사용
  const { formatDate } = useManager();

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [date, setDate] = useState<Date>(new Date(customerData.birthDate)); // 초기값을 customerData.birthDate로 설정
  const [gender, setGender] = useState(customerData.gender);
  // 'YYYY. MM. DD' 형식으로 포맷된 날짜를 저장, 초기값은 customerData.registrationDate
  const [formattedRegistrationDate, setFormattedRegistrationDate] =
    useState<string>(formatDate(new Date(customerData.registrationDate)));
  // 'YYYY. MM. DD' 형식으로 포맷된 날짜를 저장, 초기값은 customerData.birthDate
  const [formattedBirthDate, setFormattedBirthDate] = useState<string>(
    formatDate(new Date(customerData.birthDate))
  );

  const handleCalendarOpen = () => {
    setIsCalendarOpen((prev) => !prev);
  };

  // 성별 onChange 핸들러
  const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGender(event.target.value); // 선택된 값으로 상태 업데이트
  };

  // 캘린더 onChange 핸들러
  const handleDateChange = (value: Value) => {
    if (value && !Array.isArray(value)) {
      const selectedDate = value as Date;
      setDate(selectedDate); // 선택된 날짜로 상태 업데이트
      setFormattedBirthDate(formatDate(selectedDate)); // 포맷팅된 날짜 업데이트
    }
    setIsCalendarOpen(false); // 달력 닫기
  };

  return (
    <>
      <div className="flex gap-2 font-bold text-lg leading-none">
        <button onClick={onSubmit}>
          &lt; {mode === "register" ? "고객 등록" : "고객 정보 수정"}
        </button>
      </div>

      <div className="flex justify-center">
        <ul className="flex flex-col gap-6 w-1/2">
          <li className="flex items-center h-10">
            <p className="w-32 font-bold">일련번호</p>
            <p className="w-2/3">{customerData.serialNumber}</p>
          </li>
          <li className="flex items-center h-10">
            <p className="w-32 font-bold">등록일</p>
            <p className="w-2/3">{formattedRegistrationDate}</p>
          </li>
          <li className="flex items-center h-10">
            <p className="w-32 font-bold">회원계정</p>
            {mode === "register" ? (
              <>
                <input
                  className="flex-1 p-2 mr-4 border-2 border-gray-300 border-solid rounded-xl"
                  type="email"
                  defaultValue={customerData.account}
                />
                <button className="py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl">
                  확인하기
                </button>
              </>
            ) : (
              <p>{customerData.account}</p>
            )}
          </li>
          <li className="flex items-center h-10">
            <p className="min-w-32 font-bold">고객명</p>
            <input
              className="w-full p-2 border-2 border-gray-300 border-solid rounded-xl"
              type="text"
              maxLength={40}
              defaultValue={customerData.name}
            />
          </li>
          <li className="flex items-center h-10">
            <p className="w-32 font-bold">성별</p>
            <form className="flex gap-8 w-2/3">
              <label>
                <input
                  className="mr-2"
                  type="radio"
                  name="gender"
                  value="male"
                  checked={gender === "male"}
                  onChange={handleGenderChange}
                />
                남
              </label>
              <label>
                <input
                  className="mr-2"
                  type="radio"
                  name="gender"
                  value="female"
                  checked={gender === "female"}
                  onChange={handleGenderChange}
                />
                여
              </label>
            </form>
          </li>
          <li className="relative flex items-center h-10">
            <p className="w-32 font-bold">생년월일</p>
            <p className="flex-1 w-2/3 mr-4">{formattedBirthDate}</p>

            <button
              className="py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl"
              onClick={handleCalendarOpen}
            >
              날짜선택
            </button>
            {isCalendarOpen && (
              <Calendar
                className="absolute top-[48px] -right-[126px] drop-shadow-2xl"
                value={date}
                onChange={handleDateChange}
                showNeighboringMonth={false}
                calendarType="gregory"
                formatDay={(locale, date) => date.getDate().toString()}
              />
            )}
          </li>
          <li className="flex items-center h-10">
            <p className="min-w-32 font-bold">연락처</p>
            <input
              className="w-full p-2 border-2 border-gray-300 border-solid rounded-xl"
              type="tel"
              defaultValue={customerData.phone}
            />
          </li>
          <li className="flex items-center h-10">
            <p className="min-w-32 font-bold">고객분류</p>
            <input
              className="w-full p-2 border-2 border-gray-300 border-solid rounded-xl"
              type="text"
              defaultValue={customerData.customerType}
            />
          </li>
          <li className="flex items-start">
            <p className="min-w-32 font-bold">메모</p>
            <textarea
              className="w-full min-h-36 max-h-36 p-2 border-2 border-gray-300 border-solid rounded-xl overflow-y-scroll no-scrollbar break-keep leading-[110%]"
              defaultValue={customerData.memo}
              maxLength={200}
            />
          </li>
          <li className="flex justify-center">
            <button
              className="py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl"
              onClick={onSubmit}
            >
              {mode === "register" ? "등록하기" : "저장하기"}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}
