import { ReactSVG } from "react-svg";
import { TextFieldMainProps } from "src/types/props.types";

export default function TextFieldMain({
  id,
  type,
  placeholder,
  iconName,
  errorMessage,
  register,
  onInvalid,
  onInput,
  disabled,
  value
}: TextFieldMainProps) {
  const focusedStyle = "focus:border-mainColor1 focus:outline-none";
  const errorStyle = errorMessage ? "border-red80 focus:border-red-500" : "";
  const disabledStyle = disabled
    ? "bg-gray10 border-gray50 text-gray50 cursor-not-allowed"
    : "";

  return (
    <div className="relative">
      <ReactSVG
        className={`absolute h-6 w-6 left-3 top-1/2 transform -translate-y-1/2 z-10 fill-gray50`}
        src={`${process.env.PUBLIC_URL}/images/icons_update/${iconName}.svg`}
      />
      <input
        id={id}
        className={`textfield-main border border-solid border-gray50 text-black ${
          errorMessage ? errorStyle : ""
        } ${disabled ? disabledStyle : ""} ${focusedStyle}`}
        defaultValue={value}
        type={type}
        placeholder={placeholder}
        aria-invalid={errorMessage ? "true" : "false"}
        {...register}
        onInvalid={onInvalid}
        onInput={onInput}
        disabled={disabled}
      />
    </div>
  );
}
