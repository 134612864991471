import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { CategoryMainState } from "@states/DataTypeState";
import React from "react";
import { ReactSVG } from "react-svg";

export default function TopicListeningList() {
  const categoryMainState = useRecoilValue(CategoryMainState);
  const { data } = categoryMainState;
  // console.log("MobileTopicListeningOptions", data);

  return (
    <div className="grid grid-cols-2 lg:grid-cols-5 gap-4 w-full max-w-[824px]">
      {data &&
        data.length > 0 &&
        data.map((item: any) => (
          <React.Fragment key={item.code}>
            <Link
              to={`category/${item.route}`}
              className="flex flex-col justify-center items-center w-full py-3 px-4 gap-1 bg-gray10 rounded-[20px] lg:w-[152px]"
            >
              <ReactSVG
                className="size-[50px]"
                src={`${process.env.PUBLIC_URL}/images/icons_update/topic/ico_${item.route}_main.svg`}
              />
              <p className="text-xl text-gray80 font-bold">{item.name}</p>
            </Link>
          </React.Fragment>
        ))}
    </div>
  );
}
