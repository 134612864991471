import { ReactSVG } from "react-svg";

type ButtonsPlayLProps = {
  handlePlay: () => void;
  isPlay: boolean;
};

export default function ButtonsPlayL({
  handlePlay,
  isPlay
}: ButtonsPlayLProps) {
  return (
    <button
      className="flex justify-center items-center size-[84px] bg-mainColor1 rounded-full lg:size-[140px]"
      onClick={handlePlay}
    >
      <ReactSVG
        className="size-16 fill-white lg:size-24"
        src={`${process.env.PUBLIC_URL}/images/icons_update/ico_${
          isPlay ? "pause" : "play"
        }.svg`}
      />
    </button>
  );
}
