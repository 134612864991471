// 고객관리 > 청능 재활 이력 > 훈련 기록 컴포넌트

export default function ProgramInputForm() {
  return (
    <article className="flex flex-col gap-4 w-full">
      <div className="flex gap-2">
        <input type="checkbox" />
        <span>프로그램</span>
      </div>
      <div className="flex flex-col gap-8 p-4 border-2 border-gray-300 border-solid">
        <div className="flex items-center gap-4">
          <p className="w-24">진행 시간</p>
          <div className="flex justify-end items-center gap-4 w-full">
            <input
              className="w-full p-2 border-2 border-gray-300 border-solid [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              type="number"
            />
            <span>분</span>
          </div>
        </div>
        <div className="flex gap-4">
          <p className="w-24">결과</p>
          <textarea className="w-full min-h-20 max-h-20 p-2 border-2 border-gray-300 border-solid" />
        </div>
      </div>
    </article>
  );
}
