import { useState } from "react";

import Calendar from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import "react-calendar/dist/Calendar.css";

import CustomerNavigation from "./CustomerNavigation";
import StatisticsTable from "./StatisticsTable";

import statisticsData from "@data/statisticsData.json";

// 고객관리 > 말귀통계 컴포넌트

export default function Statistics() {
  const [data, setData] = useState(statisticsData);
  const [date, setDate] = useState<Date>(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleCalendarOpen = () => {
    setIsCalendarOpen((prev) => !prev);
  };

  return (
    <>
      <CustomerNavigation />

      <section className="flex flex-col gap-12 pr-10 overflow-y-scroll">
        <div className="flex">
          <p className="w-1/3 font-bold">말귀 가입일</p>
          <p>2024.05.21</p>
        </div>
        <div className="flex">
          <p className="w-1/3 font-bold">오늘의 듣기 진도</p>
          <p>21회차</p>
        </div>
        <div className="relative flex">
          <p className="w-1/3 font-bold">접속 현황</p>
          <p className="w-1/3">주간 1일 / 월간 6일 / 누적 21일</p>
          <button
            className="underline"
            onClick={handleCalendarOpen}
          >
            날짜선택
          </button>
          {isCalendarOpen && (
            <Calendar
              className="absolute top-[24px] right-[164px] drop-shadow-2xl"
              value={date}
              // onChange={handleDateChange}
              showNeighboringMonth={false}
              calendarType="gregory"
              formatDay={(locale, date) => date.getDate().toString()}
            />
          )}
        </div>
        <div className="flex">
          <p className="w-1/3 font-bold">총 이용시간</p>
          <p>10시간 23분 (방문일 평균 24.3분)</p>
        </div>

        <hr />

        <div className="flex flex-col gap-12">
          <p className="w-1/3 font-bold">청능훈련 별 기록</p>
          <article className="flex flex-col gap-4">
            <p className="font-bold">선택</p>
            <div className="flex text-center">
              <div className="flex w-full gap-8">
                <div className="w-1/5 p-2 border-2 border-gray-300 border-solid">
                  {/** @todo 실제 데이터 구조에 따라 드롭다운 UI 렌더링 */}
                  <select
                    className="w-full text-center"
                    name="type"
                  >
                    <option value="today">오늘의 듣기</option>
                    <option value="yesterday">어제의 듣기</option>
                    <option value="topic">주제별 듣기</option>
                    <option value="training">훈련별 듣기</option>
                  </select>
                </div>
                <div className="w-1/5 p-2 border-2 border-gray-300 border-solid">
                  <select
                    className="w-full text-center"
                    name="index"
                  >
                    <option value="101">101</option>
                    <option value="102">102</option>
                    <option value="103">103</option>
                    <option value="104">104</option>
                    <option value="105">105</option>
                  </select>
                </div>
              </div>
              {/** @todo 조회 기능 구현 */}
              <button className="basis-48 py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl">
                조회
              </button>
            </div>
          </article>

          <article className="flex flex-col gap-4">
            <p className="font-bold">
              {/** @todo 훈련 진행 여부 저장 (O or X) */}
              훈련 진행 여부: <span>O</span>
            </p>
          </article>

          {/** @desc 청능훈련 별 기록 > 소음하 듣기-순서화 테이블 컴포넌트 */}
          <StatisticsTable data={data} />
        </div>
      </section>
    </>
  );
}
