import { CouponStatusTableProps } from "src/types/props.types";

export default function CouponStatusTable({
  handleCouponPageOpen,
  handleHistoryChecked
}: CouponStatusTableProps) {
  return (
    <div className="flex flex-col gap-4 mt-12">
      <div className="relative flex gap-8">
        <p className="font-bold">쿠폰 지급 현황</p>
        <p className="text-green-600">기본 정렬: 지급일 최신 순</p>
        <button
          className="absolute right-0 w-1/12 py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl"
          onClick={handleCouponPageOpen}
        >
          쿠폰지급
        </button>
      </div>
      <table className="border-collapse table-fixed">
        <thead className="border-b-2 border-gray-300 border-solid">
          <tr>
            <th className="p-4">일자</th>
            <th className="p-4">종류</th>
            <th className="p-4">개수</th>
            <th className="p-4">기능</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td className="p-4">2024.07.31</td>
            <td className="p-4">7일 이용권</td>
            <td className="p-4">10</td>
            <td className="p-4">
              <button
                className="underline"
                onClick={handleHistoryChecked}
              >
                내역확인
              </button>
            </td>
          </tr>
          <tr className="text-center">
            <td className="p-4">2024.07.21</td>
            <td className="p-4">30일 이용권</td>
            <td className="p-4">100</td>
            <td className="p-4">
              <button
                className="underline"
                onClick={handleHistoryChecked}
              >
                내역확인
              </button>
            </td>
          </tr>
          <tr className="text-center">
            <td className="p-4">2024.07.21</td>
            <td className="p-4">7일 이용권</td>
            <td className="p-4">5</td>
            <td className="p-4">
              <button
                className="underline"
                onClick={handleHistoryChecked}
              >
                내역확인
              </button>
            </td>
          </tr>
          <tr className="text-center">
            <td className="p-4">2024.07.15</td>
            <td className="p-4">90일 이용권</td>
            <td className="p-4">100</td>
            <td className="p-4">
              <button
                className="underline"
                onClick={handleHistoryChecked}
              >
                내역확인
              </button>
            </td>
          </tr>
          <tr className="text-center">
            <td className="p-4">2024.07.12</td>
            <td className="p-4">7일 이용권</td>
            <td className="p-4">100</td>
            <td className="p-4">
              <button
                className="underline"
                onClick={handleHistoryChecked}
              >
                내역확인
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
