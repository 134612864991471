import { Link } from "react-router-dom";

import { ROUTES } from "@constants/common.routes";
import { ReactSVG } from "react-svg";

export default function TabsSubject(props: any) {
  const { data, refs } = props;
  if (data && data.length > 0) {
    return data.map((item: any) => {
      const isActive = location.pathname.includes(item.route);

      return (
        <Link
          ref={refs[item.route]}
          to={`${ROUTES.CATEGORY}/${item.route}`}
          className={`flex justify-center items-center gap-1 py-0.5 px-2 w-full h-[34px] rounded-[40px] whitespace-nowrap lg:py-2 lg:px-3 ${
            isActive
              ? "bg-mainColor1 text-white border border-solid border-mainColor1"
              : "bg-white text-gray60 border border-solid border-gray30"
          }`}
          key={item.route}
        >
          <ReactSVG
            className={`${!isActive ? "fill-gray60" : "fill-white"}`}
            src={`${process.env.PUBLIC_URL}/images/icons_update/topic/ico_${item.route}.svg`}
          />
          <p>{item.name}</p>
        </Link>
      );
    });
  } else {
    return <></>;
  }
}
