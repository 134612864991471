const MainFooter = () => {
  return (
    <footer className="absolute z-10 left-0 bottom-10 w-screen flex flex-col text-xs text-center text-white leading-normal font-Roboto">
      <p>Speech Ear</p>
      <p>ⓒ iHAB, Inc. all rights reserved.</p>
    </footer>
  );
};

export default MainFooter;
