import { ReactSVG } from "react-svg";
import { useLocation } from "react-router-dom";

import ButtonsMainFilled1 from "@components/common/buttons/ButtonsMainFilled1";
import { NoiseTypeOptions } from "@constants/noiseType";

type PopupSettingProps = {
  speed: number;
  noiseLevel?: number;
  noiseType?: string;
  handleShowSettings: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleSpeedChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNoiseLevelChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNoiseTypeChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

/** @todo 실제 오디오 설정이 변경되도록 수정 */
export default function PopupSetting({
  handleShowSettings,
  speed,
  handleSpeedChange,
  noiseLevel,
  handleNoiseLevelChange,
  noiseType,
  handleNoiseTypeChange
}: PopupSettingProps) {
  // 소음하 검사 페이지인지 체크
  const location = useLocation();
  const isListenInNoisePage = location.pathname.includes("/listen_in_noise/");

  // 버튼 데이터를 배열로 관리
  const speedOptions = [0, 1, 2, 3, 4];
  const noiseLevelOptions = [0, 1, 2, 3, 4];

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 responsive-padding">
      <div className="flex flex-col gap-6 w-full max-w-[600px] bg-white rounded-[20px] p-6">
        <p className="text-xl font-bold text-center">속도 및 소음 설정</p>
        <hr className="bg-gray30 h-px border-none" />
        {/* 속도 설정 */}
        <section className="flex flex-col gap-2">
          <div className="flex justify-between items-center">
            {speedOptions.map((value) => (
              <button
                key={`speed-${value}`}
                className={`size-[42px] rounded-xl text-center border border-solid font-bold ${
                  speed === value
                    ? "border-mainColor1 text-mainColor1 bg-mainColor2"
                    : "border-subColor1 text-subColor1"
                }`}
                onClick={() =>
                  handleSpeedChange({
                    target: { value: value.toString() }
                  } as React.ChangeEvent<HTMLInputElement>)
                }
              >
                {value + 1}
              </button>
            ))}
          </div>
          <div className="flex justify-between text-lg font-bold">
            <p>느리게</p>
            <p>빠르게</p>
          </div>
        </section>

        {isListenInNoisePage && (
          <>
            {/* 소음 크기 설정 */}
            <section className="flex flex-col gap-2">
              <div className="flex justify-between items-center">
                {noiseLevelOptions.map((value) => (
                  <button
                    key={`noiseLevel-${value}`}
                    className={`size-[42px] rounded-xl text-center border border-solid font-bold ${
                      noiseLevel === value
                        ? "border-mainColor1 text-mainColor1 bg-mainColor2"
                        : "border-subColor1 text-subColor1"
                    }`}
                    onClick={() =>
                      handleNoiseLevelChange &&
                      handleNoiseLevelChange({
                        target: { value: value.toString() }
                      } as React.ChangeEvent<HTMLInputElement>)
                    }
                  >
                    {value + 1}
                  </button>
                ))}
              </div>
              <div className="flex justify-between text-lg font-bold">
                <p>작게</p>
                <p>크게</p>
              </div>
            </section>

            {/* 소음 종류 설정 */}
            <section className="w-full">
              <ul className="flex justify-between items-center">
                {NoiseTypeOptions.map(({ type, label, icon }) => (
                  <li
                    key={type}
                    className="flex flex-col items-center gap-1"
                  >
                    <button
                      className={`flex justify-center items-center size-14 p-2 rounded-xl ${
                        noiseType === type
                          ? "border-gradient"
                          : "shadow-[0px_0px_8px_0px_#00000029_inset]"
                      }`}
                      onClick={() =>
                        handleNoiseTypeChange &&
                        handleNoiseTypeChange({
                          target: { value: type }
                        } as React.ChangeEvent<HTMLInputElement>)
                      }
                    >
                      <ReactSVG
                        // className="size-10"
                        src={`${process.env.PUBLIC_URL}/images/icons_update/ico_noise_${icon}.svg`}
                      />
                    </button>
                    <p>{label}</p>
                  </li>
                ))}
              </ul>
            </section>
          </>
        )}
        <ButtonsMainFilled1
          className="p-5"
          type="button"
          mode="enable"
          text="확인"
          onClick={handleShowSettings}
        />
      </div>
    </div>
  );
}
