import { useState } from "react";

import useManager from "@hooks/useManager";
import { ManagerFormProps } from "src/types/props.types";

export default function ManagerForm({
  managerData,
  onSubmit
}: ManagerFormProps) {
  // 커스텀 훅에서 날짜 형식 포맷 함수 사용
  const { formatDate } = useManager();

  const [status, setStatus] = useState(managerData.status);
  // 'YYYY. MM. DD' 형식으로 포맷된 날짜를 저장, 초기값은 customerData.registrationDate
  const [formattedRegistrationDate, setFormattedRegistrationDate] =
    useState<string>(formatDate(new Date(managerData.registrationDate)));

  // 활성 상태 onChange 핸들러
  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value); // 선택된 값으로 상태 업데이트
  };

  return (
    <>
      <div className="flex gap-2 font-bold text-lg leading-none">
        <button onClick={onSubmit}>&lt; 관리자 등록</button>
      </div>
      <div className="flex justify-center">
        <ul className="flex flex-col gap-6 w-1/2">
          <li className="flex items-center h-10">
            <p className="w-32 font-bold">일련번호</p>
            <p className="w-2/3">{managerData.serialNumber}</p>
          </li>
          <li className="flex items-center h-10">
            <p className="w-32 font-bold">등록일</p>
            <p className="w-2/3">{formattedRegistrationDate}</p>
          </li>
          <li className="flex items-center h-10">
            <p className="w-32 font-bold">회원계정</p>
            <input
              className="flex-1 p-2 mr-4 border-2 border-gray-300 border-solid rounded-xl"
              type="email"
              defaultValue={managerData.account}
            />
            <button className="py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl">
              확인하기
            </button>
          </li>
          <li className="flex items-center h-10">
            <p className="min-w-32 font-bold">관리자명</p>
            <input
              className="w-full p-2 border-2 border-gray-300 border-solid rounded-xl"
              type="text"
              maxLength={40}
              defaultValue={managerData.name}
            />
          </li>
          <li className="flex items-center h-10">
            <p className="min-w-32 font-bold">연락처</p>
            <input
              className="w-full p-2 border-2 border-gray-300 border-solid rounded-xl"
              type="tel"
              defaultValue={managerData.phone}
            />
          </li>
          <li className="flex items-center h-10">
            {/** @todo 드롭 다운 메뉴로 변경 */}
            <p className="min-w-32 font-bold">분류</p>
            <p className="w-full p-2 border-2 border-gray-300 border-solid rounded-xl">
              {managerData.category}
            </p>
          </li>
          <li className="flex items-center h-10">
            <p className="w-32 font-bold">상태</p>
            <form className="flex gap-8 w-2/3">
              <label>
                <input
                  className="mr-2"
                  type="radio"
                  name="status"
                  value="active"
                  checked={status === "active"}
                  onChange={handleStatusChange}
                />
                활성
              </label>
              <label>
                <input
                  className="mr-2"
                  type="radio"
                  name="status"
                  value="inactive"
                  checked={status === "inactive"}
                  onChange={handleStatusChange}
                />
                비활성
              </label>
            </form>
          </li>
          <li className="flex items-start">
            <p className="min-w-32 font-bold">메모</p>
            <textarea
              className="w-full min-h-36 max-h-36 p-2 border-2 border-gray-300 border-solid rounded-xl overflow-y-scroll no-scrollbar break-keep leading-[110%]"
              defaultValue={managerData.memo}
              maxLength={200}
            />
          </li>
          <li className="flex justify-center">
            <button
              className="py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl"
              onClick={onSubmit}
            >
              등록
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}
