import ButtonsSubOutlined from "@components/common/buttons/ButtonsSubOutlined";
import { useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";

type QuizTopicHeaderProps = {
  isLike: boolean;
  isViewSentence?: boolean;
  itemName: string;
  isStartSolvingQuiz?: boolean;
  handleLike: () => void;
  handleViewSentence?: () => void;
  handleSolvingQuiz?: () => void;
};

export default function QuizTopicHeader({
  isLike,
  isViewSentence,
  itemName,
  isStartSolvingQuiz,
  handleLike,
  handleViewSentence,
  handleSolvingQuiz
}: QuizTopicHeaderProps) {
  const location = useLocation();

  return (
    <div className="flex justify-start items-center gap-2 w-full h-6 lg:h-10">
      <p className="text-[22px] font-bold lg:text-[32px]">{itemName}</p>
      <button onClick={handleLike}>
        <ReactSVG
          src={`${process.env.PUBLIC_URL}/images/icons_update/ico_like_${
            isLike ? "fill" : "outline"
          }.svg`}
        />
      </button>

      {!location.pathname.includes("ordering_sentence")
        ? !isViewSentence && (
            <div className="flex justify-end flex-1 w-full">
              <ButtonsSubOutlined
                type="button"
                mode="enable"
                text="문장보기"
                onClick={handleViewSentence}
                className="h-10 py-2 px-3"
              />
            </div>
          )
        : isStartSolvingQuiz && (
            <div className="flex justify-end flex-1 w-full">
              <ButtonsSubOutlined
                type="button"
                mode="enable"
                text="문장듣기"
                onClick={handleSolvingQuiz}
                className="h-10 py-2 px-3"
              />
            </div>
          )}
    </div>
  );
}
