import { useRecoilValue } from "recoil";

import { UserAuthState } from "@states/UserAuthState";
import { ManagerCouponFormProps } from "src/types/props.types";

export default function ManagerCouponForm({
  mode,
  managerData,
  navigateToBack,
  onSubmit
}: ManagerCouponFormProps) {
  const authState = useRecoilValue(UserAuthState);
  const { profile, user } = authState;

  const date = new Date();
  const issuedDate =
    `${date.getFullYear()}.` + // 연
    `${String(date.getMonth() + 1).padStart(2, "0")}.` + // 월
    `${String(date.getDate()).padStart(2, "0")} ` + // 일
    `${String(date.getHours()).padStart(2, "0")}:` + // 시간
    `${String(date.getMinutes()).padStart(2, "0")}:` + // 분
    `${String(date.getSeconds()).padStart(2, "0")}`; // 초

  return (
    <>
      <div className="flex gap-2 font-bold text-lg leading-none">
        <button onClick={navigateToBack}>
          &lt; {mode === "history" ? "내역 확인" : "쿠폰지급"}
        </button>
      </div>

      <div className="flex justify-center">
        <ul className="flex flex-col gap-6 w-1/2">
          <li className="flex items-center h-10">
            <p className="min-w-32 font-bold">지급자</p>
            <p className="w-full">{`${profile.name}(${user.email})`}</p>
          </li>
          <li className="flex items-center h-10">
            <p className="min-w-32 font-bold">지급대상</p>
            <p className="w-full">{managerData.name}</p>
          </li>

          {mode === "history" ? (
            <>
              <li className="flex items-center h-10">
                <p className="min-w-32 font-bold">지급일</p>
                <p className="w-full">{issuedDate}</p>
              </li>
              <li className="flex items-center h-10">
                <p className="min-w-32 font-bold">쿠폰종류</p>
                <p className="w-full">180일 이용권</p>
              </li>
              <li className="flex items-center h-10">
                <p className="min-w-32 font-bold">개수</p>
                <p className="w-full">99</p>
              </li>
            </>
          ) : (
            <>
              <li className="flex items-center h-10">
                <p className="min-w-32 font-bold">쿠폰종류</p>
                <div className="w-full p-2 border-2 border-gray-300 border-solid rounded-xl">
                  <select
                    className="w-full"
                    name="coupon"
                  >
                    <option value="trial-30">트라이얼용 30일</option>
                    <option value="official-90">오피셜용 90일</option>
                    <option value="day-30">30일 이용권</option>
                    <option value="day-90">90일 이용권</option>
                  </select>
                </div>
              </li>
              <li className="flex items-center h-10">
                <p className="min-w-32 font-bold">개수</p>
                <input
                  className="w-full p-2 border-2 border-gray-300 border-solid rounded-xl"
                  type="text"
                  defaultValue={99}
                  maxLength={2}
                />
              </li>
            </>
          )}
          <li className="flex items-start">
            <p className="min-w-32 font-bold">비고</p>
            <textarea
              className="w-full min-h-24 max-h-24 p-2 border-2 border-gray-300 border-solid rounded-xl overflow-y-scroll no-scrollbar break-keep leading-[110%]"
              defaultValue="워크샵 프로모션 지급"
              maxLength={100}
            />
          </li>
          <li className="flex justify-center">
            <button
              className="py-2 px-4 bg-gray-300 border-2 border-black border-solid rounded-xl"
              onClick={onSubmit}
            >
              {mode === "history" ? "수정" : "지급"}
            </button>
          </li>
          {mode === "history" && (
            <li className="flex flex-col items-start gap-6">
              <p className="min-w-32 font-bold">쿠폰 리스트</p>
              <div className="grid grid-flow-col grid-rows-3 grid-cols-3 gap-4">
                <p>C180_001</p>
                <p>C180_002</p>
                <p>C180_003</p>
                <p>C180_004</p>
                <p>C180_005</p>
                <p>C180_006</p>
                <p>...</p>
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}
