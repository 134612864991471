import Utils from "@libs/utils";
import {
  ITrainingResponseRecord,
  TrainingResponseType
} from "@libs/common.types";
import ButtonsMainFilled2 from "@components/common/buttons/ButtonsMainFilled2";

type QuestionsMultiSelectComponentProps = {
  progress: number;
  resp: ITrainingResponseRecord;
  ansList: string[];
  correctAnswer: string[];
  selAnswer: string[];
  setSelectedAnswer: (answers: string[]) => void;
  isCompleted: boolean;
};

export default function QuestionsMultiSelectComponent({
  progress,
  resp,
  ansList,
  correctAnswer,
  selAnswer,
  setSelectedAnswer,
  isCompleted
}: QuestionsMultiSelectComponentProps) {
  const currentResp = resp[progress];

  const handleMergeAnswer = (answer: string) => {
    // 선택한 답변을 추가하거나 제거
    let answers: string[] = [];
    if (selAnswer.includes(answer)) {
      answers = selAnswer.filter((item) => item !== answer); // 선택 취소
    } else {
      answers = [...selAnswer, answer]; // 새로운 선택
    }
    setSelectedAnswer(answers);
  };

  const AnswerStatus = {
    DEFAULT: "enable",
    CORRECT: "correct",
    INCORRECT: "wrong",
    CORRECT_SELECT: "correct/select",
    INCORRECT_SELECT: "wrong/select"
  };

  const getAnswerMode = (
    resp: TrainingResponseType | undefined,
    correctAns: string[],
    selAns: string[],
    answer: string
  ) => {
    if (resp) {
      // 정답 확인 시 채점 로직
      const isCorrect = correctAns.includes(answer);
      const isSelected = selAns.includes(answer);

      if (isCorrect && isSelected) {
        return AnswerStatus.CORRECT_SELECT; // 선택된 정답
      }
      if (isCorrect && !isSelected) {
        return AnswerStatus.CORRECT; // 선택되지 않은 정답
      }
      if (!isCorrect && isSelected) {
        return AnswerStatus.INCORRECT_SELECT; // 선택된 오답
      }
      return AnswerStatus.INCORRECT; // 선택되지 않은 오답
    }

    // 정답 확인 전: 버튼 클릭 시 선택된 보기에 correct 모드 적용
    if (selAns.includes(answer)) {
      return AnswerStatus.CORRECT;
    }

    // 기본 상태
    return AnswerStatus.DEFAULT;
  };

  return (
    <div className="flex justify-center items-center flex-wrap gap-3 w-full max-w-[600px]">
      {ansList.map((answer, index) => {
        const id = `answer-${index}`;
        const mode = getAnswerMode(
          currentResp,
          correctAnswer,
          selAnswer,
          answer
        );

        return (
          <ButtonsMainFilled2
            key={id}
            type="button"
            mode={
              mode as
                | "enable"
                | "correct"
                | "wrong"
                | "correct/select"
                | "wrong/select"
            }
            text={answer}
            className="p-3 max-sm:text-sm lg:p-4"
            onClick={() => handleMergeAnswer(answer)}
            disabled={currentResp !== undefined || isCompleted}
          />
        );
      })}
    </div>
  );
}
