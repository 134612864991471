import { CouponConfirmModalProps } from "src/types/props.types";

export default function CouponConfirmModal({
  isCouponIssued,
  handleCancel,
  handleCouponIssued,
  handleReset
}: CouponConfirmModalProps) {
  return (
    <div className="fixed top-0 left-0 bg-black/20 w-screen h-screen">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-6 py-12 px-24 bg-white">
        <div>
          {!isCouponIssued ? (
            /** @todo 이용권, 개수 실제 입력값으로 변경 */
            <>
              <p>180일 이용권 99개를 지급합니다.</p>
              <p>진행하시겠습니까?</p>
            </>
          ) : (
            <p>지급 완료되었습니다.</p>
          )}
        </div>
        <div className="flex justify-between gap-6">
          {!isCouponIssued ? (
            <>
              <button
                className="w-full p-2 bg-gray-300 border-2 border-black border-solid rounded-xl"
                onClick={handleCancel}
              >
                취소
              </button>
              <button
                className="w-full p-2 bg-gray-300 border-2 border-black border-solid rounded-xl"
                onClick={handleCouponIssued}
              >
                확인
              </button>
            </>
          ) : (
            <button
              className="w-full p-2 bg-gray-300 border-2 border-black border-solid rounded-xl"
              onClick={handleReset}
            >
              확인
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
