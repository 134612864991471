import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";

import { PaginationProps } from "src/types/props.types";
import ButtonsInput from "./buttons/ButtonsInput";

export default function Pagination({
  transformedData,
  itemsPerPage,
  setCurrentPage,
  currentPage
}: PaginationProps) {
  const location = useLocation();
  const [pagesPerGroup, setPagesPerGroup] = useState<number>(() =>
    window.innerWidth < 1280 ? 5 : 10
  ); // 페이지 그룹 당 페이지 수, 모바일에서는 5개로 설정, 데스크탑에서는 10개로 설정
  const [currentPageGroup, setCurrentPageGroup] = useState(1);

  // 리사이즈 이벤트 발생 시 그룹 당 페이지 수 변경
  useEffect(() => {
    const handleResize = () => {
      setPagesPerGroup(window.innerWidth < 1280 ? 5 : 10);
    };

    window.addEventListener("resize", handleResize);

    // 초기 렌더링에서 화면 크기를 한 번 더 확인
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (location.state?.categoryData?.page) {
      const pageFromState = location.state.categoryData.page;
      setCurrentPage(pageFromState);

      // 페이지에 따라 currentPageGroup을 설정
      setCurrentPageGroup(Math.ceil(pageFromState / pagesPerGroup));
    }
  }, [location.state, setCurrentPage, setCurrentPageGroup]);

  // 전체 페이지 수 계산
  const totalPages = transformedData
    ? Math.ceil(transformedData.items.length / itemsPerPage)
    : 1;
  const totalPageGroups = Math.ceil(totalPages / pagesPerGroup);
  const startPage = (currentPageGroup - 1) * pagesPerGroup + 1;
  const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);

  const goToNextGroup = () => {
    setCurrentPageGroup((prevGroup) =>
      Math.min(prevGroup + 1, totalPageGroups)
    );
    setCurrentPage((currentPageGroup + 1 - 1) * pagesPerGroup + 1);
  };

  const goToPreviousGroup = () => {
    setCurrentPageGroup((prevGroup) => Math.max(prevGroup - 1, 1));
    setCurrentPage((currentPageGroup - 1 - 1) * pagesPerGroup + 1);
  };

  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      setCurrentPageGroup(Math.ceil(page / pagesPerGroup));
    } else if (page > totalPages) {
      setCurrentPage(totalPages);
      setCurrentPageGroup(Math.ceil(totalPages / pagesPerGroup));
    } else {
      setCurrentPage(1);
      setCurrentPageGroup(1);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center gap-3 w-full mb-[72px] lg:max-w-[600px]">
      <nav
        className="flex justify-between items-center w-full lg:justify-center lg:gap-3"
        aria-label="Pagination"
      >
        <button
          type="button"
          className="flex justify-between items-center"
          aria-label="Previous"
          onClick={goToPreviousGroup}
          disabled={currentPageGroup === 1}
        >
          <ReactSVG
            className={`size-4 ${
              currentPageGroup === 1 ? "fill-gray50" : "fill-mainColor1"
            }`}
            src={`${process.env.PUBLIC_URL}/images/icons_update/ico_left.svg`}
          />
          <span className="sr-only">Previous</span>
        </button>
        <div className="flex gap-2 lg:gap-3">
          {Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i
          ).map((page) => (
            <button
              key={page}
              type="button"
              className={`size-10 border border-solid border-mainColor1 ${
                currentPage === page ? "bg-mainColor2" : "bg-white"
              } text-mainColor1 rounded-xl`}
              aria-current={currentPage === page ? "page" : undefined}
              onMouseDown={() => goToPage(page)}
            >
              {page}
            </button>
          ))}
        </div>
        <button
          type="button"
          className="flex justify-between items-center"
          aria-label="Next"
          onClick={goToNextGroup}
          disabled={currentPageGroup === totalPageGroups}
        >
          <span className="sr-only">Next</span>
          <ReactSVG
            className={`size-4 ${
              currentPageGroup === totalPageGroups
                ? "fill-gray50"
                : "fill-mainColor1"
            }`}
            src={`${process.env.PUBLIC_URL}/images/icons_update/ico_right.svg`}
          />
        </button>
      </nav>

      {/* Go to 기능 */}
      <ButtonsInput onGoToPage={goToPage} />
    </div>
  );
}
