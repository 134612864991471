import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import TrainingLinkList from "@components/common/TrainingLinkList";
import NavigationMain from "@components/common/NavigationMain";

import { ROUTES } from "@constants/common.routes";
import { TrainingTypes } from "@constants/trainingType";

import type { TrainingGroupDataType } from "@libs/common.types";
import useAppData from "@hooks/useAppData";

export default function TopicListeningDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { main, sub } = useParams();
  const data = location.state;

  const [trainingGroup, setTrainingGroup] = useState<TrainingGroupDataType[]>(
    []
  );
  const { getTrainingGroupList } = useAppData();

  useEffect(() => {
    if (data) {
      getTrainingGroupList(data.code).then((_data) => {
        if (_data && _data.length > 0) {
          const result = _data.reduce(
            (
              acc: TrainingGroupDataType[],
              item: { training_type: string; id: string; name: string }
            ) => {
              let entry = acc.find(
                (r: { training_type: string }) =>
                  r.training_type === item.training_type
              );
              if (!entry) {
                entry = {
                  index: TrainingTypes.find((t) => t.key === item.training_type)
                    ?.index as number,
                  training_type: item.training_type,
                  items: []
                };
                acc.push(entry);
              }
              entry.items.push({ id: item.id, name: item.name });
              return acc;
            },
            []
          );
          setTrainingGroup(result);
        }
      });
    }
  }, [data]);

  const navigateToBack = () => {
    navigate(`${ROUTES.CATEGORY}/${main}`);
  };

  return (
    <main className="flex justify-center items-center w-full responsive-padding mb-[60px] mt-14 lg:mt-16">
      <div className="flex flex-col justify-center items-center gap-5 responsive-max-width lg:gap-10">
        <NavigationMain
          property="depth"
          elType="button"
          onClick={navigateToBack}
          title={data.name}
          bgColor="white"
        />
        <p className="text-sm text-gray60 lg:text-xl mt-5 lg:mt-10">
          프로그램과 테마를 선택하세요
        </p>

        {trainingGroup
          .sort((a, b) => a.index - b.index)
          .map((type: TrainingGroupDataType) => {
            const { index, training_type, items } = type;
            console.log("TrainingGroupDataType", type);
            console.log("data", data);

            return (
              <TrainingLinkList
                key={training_type}
                type={training_type}
                items={items}
                categoryData={{
                  data: data,
                  main: main,
                  sub: sub,
                  p_code: data.p_code,
                  code: data.code
                }}
              />
            );
          })}
      </div>
    </main>
  );
}
